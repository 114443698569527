import React, {Component} from 'react';

class BillingTemporalInfo extends Component {
    render(){

        return (
        <div>
            {/* <!-- The Modal --> */}
          <div>
            {/* <!-- Modal content --> */}
            <div className="cip-modal-content experience-modal-content justifyLeft">

              {/* <!--Props the SVG and the title--> */}
              <div className="upperTitleExperience">
                 <img className="experienceSVG" src="/img/modalLogos/HCPExperienceManagement.svg" alt="HCP Experience Management" />
              </div>

              {/* <!--MainTitle--> */}
              <div>
                  <div className="mainTitle">
                      <div>
                          <strong>Need to update your billing information?</strong>
                      </div>
                  </div>
              </div>

              <div className="mainInformation">
                  <div className="contentTitle">
                   If you need to update your billing information<br />call this number for assistance<a href="tel:8773078573"> 877-307-8573</a>                   						
                  </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

  export default BillingTemporalInfo;