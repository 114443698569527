import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { APP_PREFIX_PATH } from '../../../configs/AppConfig';
import { env } from '../../../configs/EnvironmentConfig';

export const WidgetAction = (props) => {
    const { isCollapsed } = props;

    // Function to open the old experience
    const openOldExperience = () => {
        window.open(`${env.VANTAGE_URI}?keep_printable=false`, "_blank");
    };

    const helpCenterLink = `${APP_PREFIX_PATH}/help-center`;

    return (
        <div className="widget-action">
            <p className="help-center-wrapper">
                {helpCenterLink ? (
                    <Link to={helpCenterLink}>
                        <img className="help-center" src="/img/others/Side-Nave-Life-Ring-Light.svg" alt="" />
                        Help Center
                    </Link>
                ) : (
                    <span>No Help Center Available</span> // Fallback or placeholder
                )}
            </p>

            {/* Use a button or an anchor tag instead of Link for non-routing actions */}
            <Button onClick={openOldExperience}>
                {isCollapsed ? (
                    <img className="help-center" src="/img/others/angle-double-left-solid.svg" alt="" />
                ) : (
                    "Back To Old Experience"
                )}
            </Button>
        </div>
    );
};

export default WidgetAction;
