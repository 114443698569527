import configurationViews from '../../../assets/data/configurationViewsByPage.data.json';
import quicksightDashboardConfigurationViewsByPage from '../../../assets/data/quicksightDashboardConfigurationViewsByPage.data.json';

export const getViewConfigurationPermissions = async(requestingPageLocation, requestingCompanyId, requestingCompanyGroupIds) => {
  try{
    console.log("$$$$requestingPageLocation");
    console.log(requestingPageLocation);
    console.log("$$$$requestingCompanyId");
    console.log(requestingCompanyId);    
    console.log("$$$$requestingCompanyGroupIds");
    console.log(requestingCompanyGroupIds);
    
    let pageViewConfiguration = {};
    const internalPageLocationKey = getRequestingPageLocationMapper(requestingPageLocation);
    // console.log("$$$$internalPageLocationKey");
    // console.log(internalPageLocationKey);

    const pageLocationconfiguration = configurationViews.find(p => p?.pageLocation === internalPageLocationKey)?.configurationView;
    // console.log("$$$$pageLocationconfiguration");
    // console.log(pageLocationconfiguration);

    const isToDisplaySingleView = (requestingCompanyGroupIds?.some((item) => pageLocationconfiguration?.displaySingleView.allowedCompanyGroups?.includes(item)) ||
                                    pageLocationconfiguration?.displaySingleView?.allowedCompanyIds?.includes(requestingCompanyId)) ? true : false; 
                                    // console.log("$$$$isToDisplaySingleView");
                                    // console.log(isToDisplaySingleView);

    const isToDisplayDualView =   (requestingCompanyGroupIds?.some((item) => pageLocationconfiguration?.displayDualView.allowedCompanyGroups?.includes(item)) ||
                                           pageLocationconfiguration?.displayDualView?.allowedCompanyIds?.includes(requestingCompanyId)) ? true : false;
                                          //  console.log("$$$$isToDisplayDualView");
                                          //  console.log(isToDisplayDualView);

    pageViewConfiguration =  {
      isToDisplaySingleView: isToDisplaySingleView,
      isToDisplayDualView: isToDisplayDualView
    }      
    console.log("$$$$pageViewConfigurationpageViewConfiguration");
    console.log(pageViewConfiguration);
    return pageViewConfiguration;

  }catch(err){
    console.error("ERROR in getViewConfigurationPermissions");
    console.error(err);
  }
}

export const getQuicksightDashboardIdsByLocation = async(requestingPageLocation) => {
  try{   

    const internalPageLocationKey = getRequestingPageLocationMapper(requestingPageLocation);

    const pageLocationconfiguration = quicksightDashboardConfigurationViewsByPage.find(p => p?.pageLocation === internalPageLocationKey)?.configurationView;

    const dashboardIds =  pageLocationconfiguration?.dashboards?.ids;                                          
    
    return dashboardIds ?? [];

  }catch(err){
    console.error("ERROR in getQuicksightDashboardIdsByLocation");
    console.error(err);
  }
}

const getRequestingPageLocationMapper = (requestingPageLocation) => {
  switch (requestingPageLocation?.toLowerCase()) {
    case "experience":
      return "xmDashboard";
    case "experience-corp":
      return "xmDashboardCorp";
    case "locations":
      return "locations";
    case "surveys":
      return "xmSurveys"; 
    case "monthly-reports":
      return "xmMonthlyReports";   
    default:
      return "";
  }
}

const GlobalConfigurationService = {
  getViewConfigurationPermissions,
  getQuicksightDashboardIdsByLocation
};

export default GlobalConfigurationService;
