import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Cip from './Cip';
import General from './General';
import Experience from './Experience';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    cip: Cip,
    general: General,
    experience: Experience
});

export default reducers;