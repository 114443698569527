import { env } from '../configs/EnvironmentConfig'
import UserService from "../services/UserService";

export const addDaysForBayadaQuarters = (date, numOfDaysToAdd) => {
  const dateCopy = new Date(date);
  return dateCopy.setDate(dateCopy.getUTCDate() + numOfDaysToAdd);
}

export const getLastDayOfQuarter = (dateString) => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const quarter = Math.floor((date.getUTCMonth()) / 3); // Determine the quarter based on the month
  const lastMonthOfQuarter = (quarter + 1) * 3; // Calculate the last month of the quarter
  const lastDay = new Date(Date.UTC(year, lastMonthOfQuarter, 0)).getUTCDate(); // Get the last day of the month before the next quarter

  const lastDayDate = new Date(Date.UTC(year, lastMonthOfQuarter - 1, lastDay));
  const formattedDate = lastDayDate.toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
  return formattedDate;

};

export const getFirstDayOfQuarter = (dateString) => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const quarter = Math.floor((date.getUTCMonth()) / 3); // Determine the quarter based on the month
  const firstMonthOfQuarter = quarter * 3; // Calculate the first month of the quarter

  const firstDayDate = new Date(Date.UTC(year, firstMonthOfQuarter, 1));
  const formattedDate = firstDayDate.toISOString().split('T')[0]; // Format the date as YYYY-MM-DD

  return formattedDate;

};

export const lastDayOfMonth = (year, month) => {
  return new Date(year, month + 1, 0).getUTCDate();
}

export const subtract6Months = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 6);
  currentDate.setUTCDate(1);
  
  if (currentDate.getUTCMonth() === 11) {
    currentDate.setUTCFullYear(currentDate.getUTCFullYear() - 1);
  }
  
  const year = currentDate.getUTCFullYear();
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getUTCDate()).padStart(2, '0');
  
  let formattedDate = [year, month, day].join('-');
  return formattedDate;
}

export const getFirstDayOfNextMonth = () => {
  let d = new Date(),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDay(),
    year = d.getUTCFullYear();

  if (month === 12 || month === '12') {
    month = 1;
    year = year + 1;
  } else {
    day = 1;
    month = d.getUTCMonth() + 2;
  }

  let formattedDate = [year, month, day].join('-');
  return formattedDate;
}

export const getQuarterOfTheYear = (date) => {
  let d = new Date(date);
  const month = d.getUTCMonth() + 1;
  return (Math.ceil(month / 3));
}

export const getCustomedQuartersBasedOnYear = (date, arrayOfDates) => {
  let wordToFilterFor = `${(new Date(date)).getUTCFullYear()}`;
  let customedArrayOfDatesByYear = arrayOfDates.filter(innerDates => {
    return (innerDates.DateRangeDisplayName.includes(wordToFilterFor))
  });

  return customedArrayOfDatesByYear;
}

export const getCustomQuarterNumber = (dateString, quarterRanges) => {
  const date = new Date(dateString);
  // Sort the quarterRanges array based on the start date
  quarterRanges.sort((a, b) => new Date(a?.DateStartedAt) - new Date(b?.DateEndedBefore));

  for (let i = 0; i < quarterRanges?.length; i++) {
    const range = quarterRanges[i];
    const startDate = new Date(range?.DateStartedAt);
    const endDate = new Date(range?.DateEndedBefore);

    if (date >= startDate && date <= endDate) {
      return i + 1; // Return the quarter number (1-indexed)
    }
  }

  return -1; // Return -1 if the date doesn't fall within any quarter range
}
// TRANSFORM TO NORMAL QUARTER NUMBER ELSE ITS GONG TO KEE SHIFTING
export const getBayadaQuarterDate = (date, arrayOfDates, endOrStart, normalQuarterNum) => {
  let datesEquivalent;
  let arrayOfCustomedDates = getCustomedQuartersBasedOnYear(date, arrayOfDates);
  let bayadaQuarterToSearchFor = `${(new Date(date)).getUTCFullYear()}Q${(normalQuarterNum > 0) ? normalQuarterNum : getCustomQuarterNumber(date, arrayOfCustomedDates)}Bayada`;

  if (arrayOfCustomedDates?.length > 0) {

    datesEquivalent = arrayOfCustomedDates?.filter(innerDates => {
      return (innerDates?.DateRangeDisplayName?.replace(/\s+/g, '') === bayadaQuarterToSearchFor?.replace(/\s+/g, ''))
    });

    if (env.ENVIROMENT !== "prod") {
      console.log("(normalQuarterNum > 0)");
      console.log((normalQuarterNum > 0));
      console.log("arrayOfCustomedDates");
      console.log(arrayOfCustomedDates);
      console.log("date");
      console.log(date);
      console.log("B-QUARTER");
      console.log(getCustomQuarterNumber(date, arrayOfCustomedDates));
      console.log("bayadaQuarterToSearchFor");
      console.log(bayadaQuarterToSearchFor);
      console.log("datesEquivalent");
      console.log(datesEquivalent);
    }

    if (datesEquivalent[0]?.DateStartedAt && endOrStart && endOrStart === "start") {
      return datesEquivalent[0]?.DateStartedAt;
    } else if (datesEquivalent && datesEquivalent[0]?.DateEndedBefore && endOrStart && endOrStart === "end") {
      return datesEquivalent[0]?.DateEndedBefore;
    } else {
      return date;
    }
  }

  return date;
}

export const getBayadaQuarterObjectFromPassedArray = (date, arrayOfDates) => {
  let datesEquivalent;
  let arrayOfCustomedDates = getCustomedQuartersBasedOnYear(date, arrayOfDates);
  let bayadaQuarterToSearchFor = `${(new Date(date)).getUTCFullYear()}Q${getCustomQuarterNumber(date, arrayOfCustomedDates)}Bayada`;
  bayadaQuarterToSearchFor = bayadaQuarterToSearchFor.replace(/-/g, "");

  if (arrayOfDates?.length > 0) {

    arrayOfDates.forEach(function (bayadaDate, key) {
      bayadaDate.index = key;
    });

    datesEquivalent = arrayOfDates?.filter(innerDates => {
      return (innerDates?.DateRangeDisplayName?.replace(/\s+/g, '') === bayadaQuarterToSearchFor?.replace(/\s+/g, ''))
    });

    if (datesEquivalent && datesEquivalent[0]) {
      return datesEquivalent[0];
    } else {
      return date;
    }
  }

  return date;
}

export const convertDateToDateString = (date) => {
  // Zeros before the date is very important so that it does not explode
  let formattedDate = '';
  let d = new Date(date),
    year = d.getUTCFullYear(),
    month = String(d.getUTCMonth() + 1).padStart(2, '0'), // Add 1 to month since it's zero-based
    day = String(d.getUTCDate()).padStart(2, '0');
  formattedDate = [year, month, day].join('-');

  return formattedDate;
}

export const formatDate = (date, dateType, dateMode) => {

  if (dateMode === 'end' && dateType === 'Quarter') {
    date = getLastDayOfQuarter(date);
  } else if (dateMode === 'start' && dateType === 'Quarter') {
    date = getFirstDayOfQuarter(date);
  }

  let formattedDate = '';

  // Format date
  let d = new Date(date + 'T00:00:00Z'), // Append 'T00:00:00Z' to "the date string" VERY IMPORANT! and it cannot be raw date else it will explode,
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  if (dateType === 'Year') {
    if (dateMode === 'end') {
      month = '0' + 1;
      year = year + 1;
      day = '0' + 1;
    } else if (dateMode === 'start') {
      month = '0' + 1;
      day = '0' + 1;
    }
  }

  if (dateType === 'Month') {
    if (dateMode === 'end') {
      if (month === 12 || month === '12') {
        month = '0' + 1;
        year = year + 1;
      } else {
        month = d.getUTCMonth() + 2;
        if (month < 10) {
          month = '0' + month;
        }
      }
      day = '0' + 1;
    } else if (dateMode === 'start') {
      day = '0' + 1;
    }
  }

  formattedDate = [year, month, day].join('-');

  return formattedDate;
}

export const getStartDefaultDateFromNextObjectFromArrayPassed = (arrayIndex = 0, arrayOfDates, numOfObjectsToSearchAhead = 1) => {
  let datesEquivalent;
  const indexToSearchFor = arrayIndex + numOfObjectsToSearchAhead;
  if (arrayIndex !== undefined && arrayOfDates?.length > 0) {
    datesEquivalent = arrayOfDates?.filter(innerDates => {
      return (innerDates?.index === indexToSearchFor)
    });
  }
  if (datesEquivalent && datesEquivalent[0]?.DateStartedAt) {
    return datesEquivalent[0]?.DateStartedAt;
  } else {
    return null;
  }
}

export const getDefaultBayadaEndDateByCustomedQuarter = (arrayOfDates) => {
  const firstDateOfNextMonth = getFirstDayOfNextMonth();
  return getBayadaQuarterDate(firstDateOfNextMonth, arrayOfDates, "end");
}

export const getDefaultBayadaStartDateByCustomedQuarter = (arrayOfDates, numOfObjectsToSearchAhead) => {
  const firstDateOfNextMonth = getFirstDayOfNextMonth();
  const defaultObjectBayadaByCustomedDate = getBayadaQuarterObjectFromPassedArray(firstDateOfNextMonth, arrayOfDates);
  return getStartDefaultDateFromNextObjectFromArrayPassed(defaultObjectBayadaByCustomedDate?.index, arrayOfDates, numOfObjectsToSearchAhead);
}

export const defaultDate = (dateRanges, groupId, startOrEnd, numOfObjectsToSearchAhead) => {
  let defaultEndDate = getFirstDayOfNextMonth();
  let defaultStartDate = subtract6Months();
  // TODO when given more time change groupId vantage to DW
  if ((groupId === 2) && dateRanges?.length > 0) {
    if (startOrEnd === "start") {
      defaultStartDate = getDefaultBayadaStartDateByCustomedQuarter(dateRanges, numOfObjectsToSearchAhead);
      UserService.setPivotedStartDate(defaultStartDate);
      return defaultStartDate;
    } else if (startOrEnd === "end") {
      defaultEndDate = getDefaultBayadaEndDateByCustomedQuarter(dateRanges);
      UserService.setPivotedEndDate(defaultEndDate);
      return defaultEndDate;
    }
  } else {
    if (startOrEnd === "start") {
      UserService.setPivotedStartDate(defaultStartDate);
      return defaultStartDate;
    } else if (startOrEnd === "end") {
      UserService.setPivotedEndDate(defaultEndDate);
      return defaultEndDate;
    }
  }
}

export const isSameDate = (dateOne, dateTwo) => {

  let date1 = new Date(dateOne);
  let date2 = new Date(dateTwo);

  // Remove the time from the dates
  date1?.setHours(0, 0, 0, 0);
  date2?.setHours(0, 0, 0, 0);

  // Compare the dates
  return date1?.getTime() === date2?.getTime();
}

export const formatTodaysDate = () => {
  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  today = dd + '-' + mm + '-' + yyyy;
  return today;
}

const DateConfigurations = {
  getFirstDayOfQuarter,
  convertDateToDateString,
  isSameDate,
  subtract6Months,
  getFirstDayOfNextMonth,
  getQuarterOfTheYear,
  getBayadaQuarterDate,
  formatDate,
  addDaysForBayadaQuarters,
  getLastDayOfQuarter,
  lastDayOfMonth,
  getStartDefaultDateFromNextObjectFromArrayPassed,
  getDefaultBayadaEndDateByCustomedQuarter,
  getDefaultBayadaStartDateByCustomedQuarter,
  defaultDate,
  formatTodaysDate
};

export default DateConfigurations;
