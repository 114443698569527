import { env } from '../../../configs/EnvironmentConfig'

const userAccountInfoEndPoint= `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/33c34e05-e8c7-11eb-91cf-326dddd3e106`;
const adminIdEndpoint = `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/77b20400-6376-11ec-91f8-326dddd3e106`;
const dwReportCompanyCompanyGroup = `${env.DATAWAREHOUSE_API_ENDPOINT}/Report_Company_CompanyGroup`;
const dwCompanyGroupImpliedMembership = `${env.DATAWAREHOUSE_API_ENDPOINT}/Report_CompanyGroup_Implied_Membership`;

const requestBody = {
  "userid": env.GUESS_ENDPOINT_USER_ID,
  "password": env.GUESS_PWD,
  "outputtype": "RawJson",
};

let _userAccount = [];

export const setVantageAgency = async(whoCalledMe) => {
  try{
    // Implement if needed
  }catch(err){
    console.log("ERROR");
    console.log(err);
  }
}

export const getAdminIdFromUsername = async(token, username, email, whoCalledMe) => {  
  let debbugingUsername = "";
  if (token && username) {
    if(username === "aalbertoarellano@homecarepulse.com"){   
      debbugingUsername = "";
    }
    requestBody["JWT"] = token;
    requestBody["Username"] = debbugingUsername ? debbugingUsername : username;
    requestBody["Email"] = email;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
    
    try{

      const results = await fetch(adminIdEndpoint, requestOptions).catch((error) => {
        console.log(`Error Retrieving accountInfo in getAdminIdFromUsername: from ${whoCalledMe}`);
        console.error(error)
      });    
      const apiResult = await results.json();
      const parsedResult = apiResult["AdminId"]; 
      return parsedResult > 0 ? parsedResult : 0;

    }catch(err){
      return 0;
    }
  }

  return 0;
}

export const getUserAccountsInfo = async(token, username, email, whoCalledMe) => {

  if(env.ENVIROMENT === "dev"){
    console.log("Token Info");
    console.log(token);
    console.log(username);
    console.log(email)
    console.log(whoCalledMe);
  }

  let debbugingUsername = "";
  if (token && username) {
    if(username === "aalbertoarellano@homecarepulse.com"){
      // debbugingUsername = "jaredw@familyrhc.com";    
      debbugingUsername = "kire@homecarepulse.com";
    }
    requestBody["JWT"] = token;
    requestBody["Username"] = debbugingUsername ? debbugingUsername : username;
    requestBody["Email"] = email;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
    
    try{

      const results = await fetch(userAccountInfoEndPoint, requestOptions).catch((error) => {
        console.log(`Error Retrieving accountInfo: from ${whoCalledMe}`);
        console.error(error)
      });    
      const apiResult = await results.json();
      const parsedResult = apiResult["VantageWebUserAccounts"];
  
      return parsedResult.length > 0 ? parsedResult : _userAccount;

    }catch(err){
      return _userAccount;
    }
  }

  return _userAccount;
}

export const getCompanyGroupIdsAssociatedToCompanyId = async(companyId, token, whoCalledMe) => {
  let apiResult = [];
  if(env.ENVIROMENT !== "prod"){
    console.log("getCompanyGroupIdsAssociatedToCompanyId Token Info");
    console.log(token);
    console.log(companyId);
  }

  if (token) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    if(env.ENVIROMENT !== "prod"){
      console.log("Account Service requestOptions");
      console.log(requestOptions);
    }
    
    const dwCompanyGroupIdEndpoint = `${dwReportCompanyCompanyGroup}?select=CompanyGroupId&CompanyId=in.(${companyId})`;

    try{   
      const results = await fetch(dwCompanyGroupIdEndpoint, requestOptions).catch((error) => {
        console.log(`Error Retrieving CompanyGroupIds Info in getCompanyGroupIdsAssociatedToCompanyId: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiDwResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("getCompanyGroupIdsAssociatedToCompanyId apiDWResult");
          console.log(apiDwResult);
        }

        return apiDwResult
      }

    }catch(err){
      console.error("ERROR processing Account Service");
      return apiResult;
    }
  }
  console.log("EMPTY CompanyGroupIds");
  return apiResult;
}

export const getCompanyGroupIdsAssociatedToCompanyGroupId = async(companyGroupId, token, whoCalledMe) => {
  let apiResult = [];
  if(env.ENVIROMENT !== "prod"){
    console.log("getCompanyGroupIdsAssociatedToCompanyGroupId Token Info");
    console.log(token);
    console.log(companyGroupId);
  }

  if (token) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    if(env.ENVIROMENT !== "prod"){
      console.log("Account Service requestOptions");
      console.log(requestOptions);
    }
    
    const dwCompanyGroupIdEndpoint = `${dwCompanyGroupImpliedMembership}?select=CompanyGroupId_Corp&CompanyId_Parent=in.(${companyGroupId})`;

    try{   
      const results = await fetch(dwCompanyGroupIdEndpoint, requestOptions).catch((error) => {
        console.log(`Error Retrieving CompanyGroupIds Info in getCompanyGroupIdsAssociatedToCompanyGroupId: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiDwResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("getCompanyGroupIdsAssociatedToCompanyGroupId apiDWResult");
          console.log(apiDwResult);
        }

        return apiDwResult
      }

    }catch(err){
      console.error("ERROR processing Account Service");
      return apiResult;
    }
  }
  console.log("EMPTY CompanyGroupIds");
  return apiResult;
}

const AccountService = {
  getUserAccountsInfo,
  setVantageAgency,
  getAdminIdFromUsername,
  getCompanyGroupIdsAssociatedToCompanyId,
  getCompanyGroupIdsAssociatedToCompanyGroupId
};

export default AccountService;
