import { env } from '../../../configs/EnvironmentConfig'
import newViewAllowedProviders from '../../../assets/data/newViewAllowedProviders.data.json';

const dwcompanygroup = `${env.DATAWAREHOUSE_API_ENDPOINT}/CompanyGroup?select=*,Company_CompanyGroup!company_companygroup_fk_companygroup(*,Company!company_companygroup_fk_company(*)),CompanyGroupSync!companygroupsync_fk_companygroupsource(*)&Company_CompanyGroup.Company.HasActiveSubscription=is.true&IsDemo=is.false&order=CompanyGroupName.asc`;

let apiResult = [];

export const getAllLocationsOfCompanyGroupByEmailId = async(token, whoCalledMe) => {

  if(env.ENVIROMENT !== "prod"){
    console.log("Locations Token Info");
    console.log(token);
    console.log(whoCalledMe);
  }

  if (token) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    if(env.ENVIROMENT !== "prod"){
      console.log("Locations requestOptions");
      console.log(requestOptions);
    }

    try{   
      const results = await fetch(dwcompanygroup, requestOptions).catch((error) => {
        console.log(`Error Retrieving Location Info in getAllLocationsOfCompanyGroupByEmailId: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiLocationsResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("Locations Results");
          console.log(apiLocationsResult);
        }

        return apiLocationsResult
      }

    }catch(err){
      console.error("ERROR processing Locations");
      return apiResult;
    }
  }
  console.log("EMPTY LOCATIONS");
  return apiResult;
}

export const isToDisplaySingleView = async(requestingCompanyId, requestingGroupId) => {
  const permissions = newViewAllowedProviders[0].toAccessSingleView;
  return (permissions?.allowedCompanyGroups?.includes(requestingGroupId) || permissions?.allowedCompanyIds?.includes(requestingCompanyId)) ? true : false;
}

export const isToDisplayDualView = async(requestingCompanyId, requestingGroupId) => {
  const permissions = newViewAllowedProviders[0].toAccessDualView;
  return (permissions?.allowedCompanyGroups?.includes(requestingGroupId) || permissions?.allowedCompanyIds?.includes(requestingCompanyId)) ? true : false;
}

const LocationsService = {
  getAllLocationsOfCompanyGroupByEmailId,
  isToDisplaySingleView,
  isToDisplayDualView
};

export default LocationsService;
