import React, { useEffect } from "react";
import AppLocale from "../lang";
import useBodyClass from "../hooks/useBodyClass";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { DEFAULT_PREFIX_VIEW, APP_PREFIX_PATH } from "../configs/AppConfig";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AppLayout from "../layouts/app-layout";
import AccountSelection from '../views/app-views/account-selection';
import { getWebVantageUsers, isSignedUserAnHcpAdmin, getServiceRequested, getSelectedVantageCompanyData } from '../redux/actions/Cip';
import { bindActionCreators } from 'redux';

export const Views = (props) => {

    const { locale, location, direction,
        totalNumberOfAccounts, getWebVantageUsers,
        isSignedUserAnHcpAdmin, isHcpAdmin, companyHasBeenSelected } = props;
    const localization = locale ? locale : 'en';
    const currentAppLocale = AppLocale[localization];
    useBodyClass(`dir-${direction}`);

    // Use useEffect to dispatch Redux actions on component mount
    useEffect(() => {
        isSignedUserAnHcpAdmin(); // Check if the signed-in user is an HCP Admin
        getWebVantageUsers();     // Fetch WebVantage users
    }, [isSignedUserAnHcpAdmin, getWebVantageUsers, companyHasBeenSelected]);
    

    if (!isHcpAdmin && (totalNumberOfAccounts && totalNumberOfAccounts > 1) && (!companyHasBeenSelected)) {        
        return (
            <div>
                <AccountSelection />
            </div>
        )
    } else {        
        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={APP_PREFIX_PATH ? APP_PREFIX_PATH : DEFAULT_PREFIX_VIEW} />
                        </Route>
                        <Route path={APP_PREFIX_PATH ? APP_PREFIX_PATH : DEFAULT_PREFIX_VIEW}>
                            <AppLayout direction={direction} location={location} />
                        </Route>
                    </Switch>
                </  ConfigProvider>
            </IntlProvider>
        )
    }

}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWebVantageUsers: getWebVantageUsers,
        isSignedUserAnHcpAdmin: isSignedUserAnHcpAdmin,
        getServiceRequested: getServiceRequested,
        getSelectedVantageCompanyData: getSelectedVantageCompanyData
    }, dispatch)
}

// This gets triggered first before it goes to the "const Views"
// This connects us with Redux to pass the "props" as if it was a Session
const mapStateToProps = ({ theme, auth, cip }) => {
    const { vantageWebAccounts, totalNumberOfAccounts, isHcpAdmin, companyHasBeenSelected } = cip;
    const { locale, direction } = theme;
    const { token } = auth;
    return { locale, token, direction, vantageWebAccounts, totalNumberOfAccounts, isHcpAdmin, companyHasBeenSelected }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
