export const BAYADA_DW_COMPANY_GROUP_ID = 'c47b82c2-35c1-11eb-9010-4b3000001618';
export const BAYADA_VANTAGE_COMPANY_GROUP = 2;
export const BAYADA_COMPANY_ID = 1618;
export const RIGHT_AT_HOME_USA_DW_COMPANY_GROUP_ID = 'c47b82c2-35c1-11eb-9010-4b3000001748'
export const NPS = 'nps';
export const OSAT = 'osat';
export const SURVEYS = 'surveys';
export const ALL_QUESTIONS = 'allQuestions';
export const CLIENTS = 'clients';
export const GOALS = 'goals';
export const CEX = 'cex';
export const DNC = 'dnc';
export const CSV_DOWNLOAD = 'CSV';
export const PDF_DOWNLOAD = 'PDF';