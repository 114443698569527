const agencies = [
    {
        "agencyId":1632,
        "agencyName":"Home Helpers",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":1639,
        "agencyName":"SYNERGY HomeCare",
        "flags": {
            "em":1,
            "rm":0,
            "el":0
        }
    },
    {
        "agencyId":1748,
        "agencyName":"Right at Home",
        "flags": {
            "em":0,
            "rm":1,
            "el":0
        }
    },
    {
        "agencyId":2196,
        "agencyName":"BrightStar Care",
        "flags": {
            "em":0,
            "rm":0,
            "el":1
        }
    },
    {
        "agencyId":4567,
        "agencyName":"Briggs Homecare",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":4815,
        "agencyName":"New Horizons In-Home Care Solutions",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":4836,
        "agencyName":"Well Care Home Care",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":5112,
        "agencyName":"Outreach Health Services",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":5155,
        "agencyName":"Honor Health Network",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":5751,
        "agencyName":"Community Care Home Health Services",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":5790,
        "agencyName":"MGA Home Healthcare",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    },
    {
        "agencyId":5892,
        "agencyName":"Kindred at Home",
        "flags": {
            "em":1,
            "rm":1,
            "el":1
        }
    }
];

const sales = {
    "expertName": "Drew Chacho",
    "expertTitle": "Account Manager",
    "calendarLink": "https://meetings.hubspot.com/andrewchacho/hcp-meeting-calendar",
    "department": "sales"
  };
  
const support = {
    "expertName": "Customer Support",
    "expertTitle": "Let us help you",
    "calendarLink": "https://meetings.hubspot.com/jessicaimbler/meet-with-support",
    "department": "support"
  };

export const getSpecificAgentBasedOnCriteria = (selectedAgencyId, selectedService) => {
    const index = agencies.findIndex(agency => agency.agencyId === selectedAgencyId);
    if(index >= 0){
        const specialAgency = agencies[index];
        const flags = specialAgency["flags"];
        const agentInfoObject = flags[selectedService] === 1 ? sales : support; 
       return agentInfoObject;
    }

    return sales;
}

const ExpertSupportServiceForAgency = {
    getSpecificAgentBasedOnCriteria
  };
  
  export default ExpertSupportServiceForAgency;
  