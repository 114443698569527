import { env } from '../configs/EnvironmentConfig'

const awsUrlMainEndpoint = `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/`;

const requestBody = {
  "userid": env.GUESS_ENDPOINT_USER_ID,
  "password": env.GUESS_PWD,
  "outputtype": "RawJson",
};

let _url = "";


export const getUserAwsArn = async(token, whoCalledMe) => {
  
  if (token) {    
    requestBody["Token"] = token

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
    
    const urlAwsArn = `${awsUrlMainEndpoint}7dffe41f-f8d7-11ed-927e-326dddd3e106`;
    try{
      const results = await fetch(urlAwsArn, requestOptions).catch((error) => {
        console.log(`Error Retrieving QuickSight getUserAwsArn: from ${whoCalledMe}`);
        console.error(error)
      });    

      const apiResult = await results.json();
      
      if(env.ENVIROMENT !== "prod"){
        console.log("urlAwsArn");
        console.log(urlAwsArn);
        console.log("getUserAwsArn HEADERS URLS");
        console.log(requestOptions);
        console.warn("Quicksight ARN");
        console.warn(apiResult);
      }
      
      const parsedResult = apiResult["UserArn"];
      return parsedResult ? parsedResult : _url;
    }catch(err){            
      return _url;
    }
  }

  return _url;
}

export const getEmbeddedReportUrlForRegisteredUser = async(token, userArn, requestedReportIds, companyId, whoCalledMe) => {
  
  if (token && userArn && requestedReportIds?.length > 0) {
    requestBody["Token"] = token;
    requestBody["DashboardIds"] = requestedReportIds;
    requestBody["UserArn"] = userArn;
    requestBody["CompanyId"] = companyId;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
    
    const urlAwsArn = `${awsUrlMainEndpoint}780619c1-f8db-11ed-927e-326dddd3e106`;

    try{
      const results = await fetch(urlAwsArn, requestOptions).catch((error) => {
        console.log(`Error Retrieving QuickSight EmbeddedUrls: from ${whoCalledMe}`);
        console.error(error)
      });    
      const apiResult = await results.json();

      if(env.ENVIROMENT !== "prod"){
        console.log("urlAwsArn");
        console.log(urlAwsArn);
        console.log("QuickSight HEADERS URLS");
        console.log(requestOptions)
        console.warn("Quicksight Results");
        console.warn(apiResult);
      }

      const parsedResult = apiResult["Dashboards"];   
      const urls = parsedResult.map(obj => obj.Url);
      return urls?.length > 0 ? urls : [];

    }catch(err){            
      return null;
    }
  }

  return [];
}

export const getDashboardUrlReportForRegisteredUser = async(token, companyId, reportMonth, requestedReport, whoCalledMe) => {
  
  if ((token) && (companyId || companyId > 0)) {
    // requestBody["OptionalJwtAccessToken"] = token;    
    // requestBody["CompanyId"] = companyId;
    console.warn(requestedReport);
    console.warn("requestedReport");
    requestBody["urlId"] = requestedReport;
    requestBody["sessionLifeTime"] = 20;
    // requestBody["userRequesting"] = 'albertoarellano';



    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("QuickSight HEADERS URLS");
      console.log(requestOptions)
      console.log("Company Id to QuickSight");
      console.log(companyId);
    }
    
    const urlAwsArn = `${awsUrlMainEndpoint}780619c1-f8db-11ed-927e-326dddd3e106`;

    try{
      const results = await fetch(urlAwsArn, requestOptions).catch((error) => {
        console.log(`Error Retrieving QuickSight Url: from ${whoCalledMe}`);
        console.error(error)
      });    
      const apiResult = await results.json();
      console.warn("Quicksight Results");
      console.warn(apiResult)
      const parsedResult = apiResult["JsonDeserializeCommandNameResponse_Output"];
      const urlFromQuickSight = parsedResult?.EmbedUrl;
      return urlFromQuickSight ? urlFromQuickSight : [];


    }catch(err){            
      return null;
    }
  }

  return null;
}

export const embedQuicksightDashboard = async(options, contentOptions) => {
  try{    
    if(options){              
      const embeddedDashboardCallback = await window.amazonQuickSightEmbeddingSDK;
      await embeddedDashboardCallback.embedDashboard(options, contentOptions);      
    }
  }catch(err){
    console.error("ERROR IN QuicksightService embedQuicksightDashboard");
    console.error(err);
  }
}

const QuickSightService = {  
  getDashboardUrlReportForRegisteredUser,
  getUserAwsArn,
  getEmbeddedReportUrlForRegisteredUser,
  embedQuicksightDashboard
};

export default QuickSightService;
