import { env } from '../../../configs/EnvironmentConfig'
import dataWH from '../../../assets/data/dummyDW.data.json';

const dwcorrections = `${env.DATAWAREHOUSE_API_ENDPOINT}/ContactCorrection?select=*,Contact!contactcorrection_fk_contact(*,Contact_Phone!contact_phone_fk_contact(*),Contact_Email!contact_email_fk_contact(*),Contact_ExternalSource!contact_externalsource_fk_contact(*,ExternalSource!contact_externalsource_fk_externalsource(*))),ContactTerm!contactcorrection_fk_contactterm!inner(*)&CorrectionClearedAt=is.null`;
let apiResult = [];

export const getAllCorrectionsByContactType = async(token,  contactType,  whoCalledMe) => {

  if(env.ENVIROMENT !== "prod"){
    console.log("Corrections Token Info");
    console.log(token);
    console.log(whoCalledMe);
  }

  if (token && contactType) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    let filterByUrl = `${dwcorrections}&ContactTerm.ContactRelationId=eq.${contactType}`;

    if(env.ENVIROMENT !== "prod"){
      console.log("Corrections requestOptions");
      console.log(requestOptions);
      console.log(filterByUrl);
    }

    try{   
      const results = await fetch(dwcorrections, requestOptions).catch((error) => {
        console.log(`Error Retrieving Location Info in getAllCorrectionsByContactType: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(env.ENVIROMENT !== "prod"){
        console.log("Corrections results");
        console.log(results);
      }

      if(results.status === 200){
        const apiCorrectionsResult = await results.json();
        console.log("apiCorrectionsResult");
        console.log(apiCorrectionsResult);
        // return apiCorrectionsResult
      }

    }catch(err){
      console.error("ERROR processing Corrections");
      return apiResult;
    }
  }
  // console.log("EMPTY Corrections");
  // return apiResult;
  // TODO" RETURN ACTUAL DATA
  return dataWH;
}

export const updateCorrectionById = async(token,  correction,  whoCalledMe) => {

  // if(env.ENVIROMENT !== "prod"){
  //   console.log("Locations Token Info");
  //   console.log(token);
  //   console.log(whoCalledMe);
  // }

  // if (token && contactType) {  
  //   let myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${token}`);
  //   myHeaders.append("Accept-Profile", "Agp");
  //   myHeaders.append("Content-Profile", "Agp");
    
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };
    
  //   let filterByUrl = `${dwcorrections}&ContactTerm.ContactRelationId=eq.${contactType}`;

  //   if(env.ENVIROMENT !== "prod"){
  //     console.log("Corrections requestOptions");
  //     console.log(requestOptions);
  //     console.log(filterByUrl);
  //   }

  //   try{   
  //     const results = await fetch(dwcorrections, requestOptions).catch((error) => {
  //       console.log(`Error Retrieving Location Info in getAllCorrectionsByContactType: from ${whoCalledMe}`);
  //       console.error(error)
  //       return apiResult;
  //     }); 

  //     if(env.ENVIROMENT !== "prod"){
  //       console.log("Corrections results");
  //       console.log(results);
  //     }

  //     if(results.status === 200){
  //       const apiCorrectionsResult = await results.json();
  //       console.log("apiCorrectionsResult");
  //       console.log(apiCorrectionsResult);
  //       // return apiCorrectionsResult
  //     }

  //   }catch(err){
  //     console.error("ERROR processing Corrections");
  //     return apiResult;
  //   }
  // }
  // console.log("EMPTY Corrections");
  // return apiResult;
  // TODO" RETURN ACTUAL DATA
  return dataWH;
}

const ContactService = {
  getAllCorrectionsByContactType
};

export default ContactService;
