import React, {Component} from 'react'
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import {getWebVantageUsers, onSelectingACompanyFromMultiCompanyGroupAccount}  from '../../../redux/actions/Cip';
import IconFallback from "../../../components/util-components/IconFallback";
import { withRouter } from "react-router-dom";

class AccountSelection extends Component {
	// Display all the accounts that are in the UserService that have no IsSelectedAgency to true
	// as the select one then have the selected object values saved and dispatch into redux (Creat the connection)
	// find a way to go back to where it was called from
	
    componentDidMount() {
		getWebVantageUsers();
	}

	processSelectedCompany = (company) => {		
		this.props.onSelectingACompanyFromMultiCompanyGroupAccount(company);			
	}

	render(){  
		const listOfAccounts =  this.props.vantageWebAccounts.map((account, i) => 
			<div key={i} className="single-web-account" onClick={() => this.processSelectedCompany(account)}>
				<div className="single-acc-arrow">
					<IconFallback path={"../img/sidebar/Account-Chevron-Right.svg"} />
				</div>
				<div>
					<h5>{account?.CompanyName}</h5>
				</div>
			</div>			
		)

        return(	
			<div>
				<div className="single-web-account-cip-modal">
				<div className="single-web-account-cip-modal-content">
				<IconFallback path={"../img/mainnav/Experience.svg"} />
					<div>
						<div className="information-account">
							<h2>Select Account</h2>
							<p>Choose an account to sign into:</p>
						</div>

						<div>
							{listOfAccounts}							
						</div>

						<br />
						<br />
						<div>
							Need help?<br />Email <a href="mailto:customersupport@homecarepulse.com">customersupport@homecarepulse.com</a> 
							<br />
							or call<a href="tel:8773078573"> 877-307-8573.</a>
						</div>
					</div>						
				</div>
				</div>
			</div>
		)
    }
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({
		onSelectingACompanyFromMultiCompanyGroupAccount: onSelectingACompanyFromMultiCompanyGroupAccount,
		getWebVantageUsers:getWebVantageUsers
	}, dispatch)
}

const mapStateToProps = ({cip}) => {
	const {companyHasBeenSelected, vantageWebAccounts} = cip ;
	return {companyHasBeenSelected, vantageWebAccounts} 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSelection));
