export const EFRONT_COOKIE = 'EFRONT_COOKIE';
export const GET_EFRONT_COOKIE_FROM_API = 'GET_EFRONT_COOKIE_FROM_API';
export const GO_TO_EFRONT_AS_LOGGED_USER = 'GO_TO_EFRONT_AS_LOGGED_USER';
export const CIP_TEST = 'CIP_TEST';
export const GET_EFRONT_REFRESHED_URL = 'GET_EFRONT_REFRESHED_URL';
export const GET_REVIEW_MANAGER_URL = 'GET_REVIEW_MANAGER_URL';
export const GET_REVIEW_MANAGER_ACCOUNTS_PER_USERNAME = 'GET_REVIEW_MANAGER_ACCOUNTS_PER_USERNAME';
export const IS_SERVICE_AVAILABLE_FOR_USER = 'IS_SERVICE_AVAILABLE_FOR_USER';
export const IS_REVIEW_MANAGER_AVAILABLE_FOR_USER = 'IS_REVIEW_MANAGER_AVAILABLE_FOR_USER';
export const GET_VANTAGE_WEB_USER_ACCOUNTS = 'GET_VANTAGE_WEB_USER_ACCOUNTS';
export const ON_SELECTING_A_COMPANY_FROM_MULTI_COMPANY_GROUP_ACCOUNT = 'ON_SELECTING_A_COMPANY_FROM_MULTI_COMPANY_GROUP_ACCOUNT';
export const IS_THERE_A_SELECTED_WEB_ACCOUNT = 'IS_THERE_A_SELECTED_WEB_ACCOUNT';
export const GET_SELECTED_VANTAGE_WEB_ACCOUNT_DATA = 'GET_SELECTED_VANTAGE_WEB_ACCOUNT_DATA';
export const GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_CLICK = 'GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_CLICK';
export const GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_LOAD = 'GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_LOAD';
export const REDIRECT_VANTAGE_VERSION_OF_REVIEW_MANAGER = 'REDIRECT_VANTAGE_VERSION_OF_REVIEW_MANAGER';
export const IS_HCP_ADMIN = 'IS_HCP_ADMIN';
export const SET_TYPE_OF_SUBMENU = 'SET_TYPE_OF_SUBMENU';
export const IS_THERE_REQUESTED_SERVICE = 'IS_THERE_REQUESTED_SERVICE';
export const IS_USERS_PAGE_CLICKED_ON = 'IS_USERS_PAGE_CLICKED_ON';
export const GET_EXPERT_CALL_TO_ACTION_OBJECT = 'GET_EXPERT_CALL_TO_ACTION_OBJECT';
export const DISPLAY_TRAINING_LOGIN ='DISPLAY_TRAINING_LOGIN';
export const SUBMIT_TRAINING_CREDENTIALS = 'SUBMIT_TRAINING_CREDENTIALS';
export const IS_TRAINING_SERVICE_AVAILABLE_FOR_USER = 'IS_TRAINING_SERVICE_AVAILABLE_FOR_USER';
export const SET_EXPERIENCE_TOOGLE_FLAG = 'SET_EXPERIENCE_TOOGLE_FLAG';
export const GET_EXPERIENCE_TOOGLE_FLAG = 'GET_EXPERIENCE_TOOGLE_FLAG';
export const LOG_FEATURE_USER_INTERACTION = 'LOG_FEATURE_USER_INTERACTION';
export const ON_CAHPS_REPORTS_REQUEST = 'ON_CAHPS_REPORTS_REQUEST';
export const GET_MULTIPLE_REQUESTED_URLS_FOR_PDF = 'GET_MULTIPLE_REQUESTED_URLS_FOR_PDF';
export const RESET_QUICKSIGHT_URL = 'RESET_QUICKSIGHT_URL';
export const DISPLAY_HUBSPOT_FORM_DEMO_REQUEST = 'DISPLAY_HUBSPOT_FORM_DEMO_REQUEST';