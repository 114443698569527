import {
  GET_EFRONT_REFRESHED_URL,
  GET_REVIEW_MANAGER_URL,
  GET_REVIEW_MANAGER_ACCOUNTS_PER_USERNAME,
  IS_SERVICE_AVAILABLE_FOR_USER,
  IS_REVIEW_MANAGER_AVAILABLE_FOR_USER,
  GET_VANTAGE_WEB_USER_ACCOUNTS,
  ON_SELECTING_A_COMPANY_FROM_MULTI_COMPANY_GROUP_ACCOUNT,
  GET_SELECTED_VANTAGE_WEB_ACCOUNT_DATA,
  GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_CLICK,
  REDIRECT_VANTAGE_VERSION_OF_REVIEW_MANAGER,
  IS_HCP_ADMIN,
  IS_THERE_REQUESTED_SERVICE,
  GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_LOAD,
  IS_USERS_PAGE_CLICKED_ON,
  GET_EXPERT_CALL_TO_ACTION_OBJECT,
  DISPLAY_TRAINING_LOGIN,
  SUBMIT_TRAINING_CREDENTIALS,
  IS_TRAINING_SERVICE_AVAILABLE_FOR_USER,
  SET_EXPERIENCE_TOOGLE_FLAG,
  GET_EXPERIENCE_TOOGLE_FLAG,
  LOG_FEATURE_USER_INTERACTION,
  GET_MULTIPLE_REQUESTED_URLS_FOR_PDF,
  DISPLAY_HUBSPOT_FORM_DEMO_REQUEST
} from '../constants/Cip';

const initState = {
  isTodisplayTrainingLoging: false
};

const cip = (state = initState, action) => {
  
  switch (action.type) {
    case DISPLAY_HUBSPOT_FORM_DEMO_REQUEST:
      return {
        ...state,
        isTodisplayHubspotFormDemoRequest: action.isTodisplayHubspotFormDemoRequest
      }
    case GET_MULTIPLE_REQUESTED_URLS_FOR_PDF:
      return {
        ...state,
        pdfRequestedUrls: action.pdfRequestedUrls
      }       
    case LOG_FEATURE_USER_INTERACTION:
      return {
        ...state,
        wasLoggingSuccesful : action.wasLoggingSuccesful
      }
    case SET_EXPERIENCE_TOOGLE_FLAG:
    case GET_EXPERIENCE_TOOGLE_FLAG:
      return {
        ...state,
        exagoRequestedFlag : action.exagoRequestedFlag
      }
    case SUBMIT_TRAINING_CREDENTIALS:
      return {
        ...state,
        areCredentialsMappedSuccesfully : action.areCredentialsMappedSuccesfully,
        haveCredentialsbeenSubmited: action.haveCredentialsbeenSubmited,
        responseServerMessage: action.responseServerMessage
      }
    case DISPLAY_TRAINING_LOGIN:
      return {
        ...state,
        isTodisplayTrainingLoging: action.isTodisplayTrainingLoging
      }
    case GET_EXPERT_CALL_TO_ACTION_OBJECT:
      return{
        ...state,
        expertObject: action.expertObject
      }
    case IS_USERS_PAGE_CLICKED_ON:
      return {
        ...state,
        isUsersPageClicked : action.isUsersPageClicked
      }
    case IS_THERE_REQUESTED_SERVICE:      
      return{
        ...state,
        requestedService: action.requestedService,
        isUserComingFromEL: action.isUserComingFromEL
      } 
    case IS_HCP_ADMIN:
      return{
        ...state,
        isHcpAdmin: action.isHcpAdmin
      };
    case REDIRECT_VANTAGE_VERSION_OF_REVIEW_MANAGER:
      return {
        ...state
      }; 
    case GET_SELECTED_VANTAGE_WEB_ACCOUNT_DATA: 
      return {
        ...state,
        selectedAgencyData: action.selectedAgency
      };
    case ON_SELECTING_A_COMPANY_FROM_MULTI_COMPANY_GROUP_ACCOUNT:
      return {
        ...state,
        selectedVantageAgency: action.selectedWebCompany,
        companyHasBeenSelected: action.companyHasBeenSelected
      };
    case GET_VANTAGE_WEB_USER_ACCOUNTS:
      return {
        ...state,
        vantageWebAccounts: action.userWebAccounts,
        totalNumberOfAccounts:action.totalNumberOfWebAccounts,
        isThereDefaultAccount: action.isThereASelectedAccount
      }; 
    case GET_EFRONT_REFRESHED_URL:      
      return {
        ...state,
        eLearnUrl: action.url,
        isSubscribedToElearning: action.isSubscribedToElearning
      }; 
    case GET_REVIEW_MANAGER_URL:
      return {
        ...state,
        url: action.url
      }; 
    case GET_REVIEW_MANAGER_ACCOUNTS_PER_USERNAME:      
      return {
        ...state,
        url: action.url
      }; 
    case IS_SERVICE_AVAILABLE_FOR_USER:         
      return {
      ...state,
      isSubscribedToThisService: action.isSubscribedToService
     };
     case IS_TRAINING_SERVICE_AVAILABLE_FOR_USER:
       return{
        ...state,
        isSubscribedToTraining: action.isSubscribedToTraining
       };
     case IS_REVIEW_MANAGER_AVAILABLE_FOR_USER:      
      return {
      ...state,
      isSubscribedToRM: action.isSubscribedToRM
     };
     case GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_CLICK:
      return {
        ...state,
        selectedService: action.service
      };
      case GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_LOAD:
        return {
          ...state,
          serviceOnLoad: action.serviceOnLoad
        };
    default:
      return state;
  }
};

export default cip