import { APP_PREFIX_PATH } from '../../AppConfig';
import { env } from "../../EnvironmentConfig";
import kellyReportPermissions from '../../../assets/data/kellyAllowedUsers.data.json'
import dncAllowedAgencies from '../../../assets/data/DncAllowedAgencies.data.json'
import * as companyConst from 'constants/CompanyConstant';

export const getCompanyGroupReportNestedSubmenu = (companyId, userName, groupId, companyGroupIds) => {

	const dashBoardNavTree = [
		{
			key: 'sidenav.experience.reports.corp.reportReference',
			path: `${APP_PREFIX_PATH}/experience/report-reference`,
			title: 'sidenav.experience.reports.corp.reportReference',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'sidenav.experience.reports.corp.satisfactionManagement',
			path: `${APP_PREFIX_PATH}/experience/satisfaction-management`,
			title: 'sidenav.experience.reports.corp.satisfactionManagement',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'sidenav.experience.reports.corp.comparison',
			path: `${APP_PREFIX_PATH}/experience/comparison`,
			title: 'sidenav.experience.reports.corp.comparison',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'sidenav.experience.reports.corp.trends',
			path: `${APP_PREFIX_PATH}/experience/trends`,
			title: 'sidenav.experience.reports.corp.trends',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		}
	]
	
	if(groupId === 2 || companyGroupIds?.includes(companyConst.RIGHT_AT_HOME_USA_DW_COMPANY_GROUP_ID)){
		dashBoardNavTree.push({
			key: 'sidenav.experience.surveys',
			path: `${APP_PREFIX_PATH}/experience/surveys`,
			title: 'sidenav.experience.surveys',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		})
	}

	if(isToAllowKellyReport(companyId, userName)){
		dashBoardNavTree.push({
			key: 'sidenav.experience.reports.corp.kelly',
			path: `${APP_PREFIX_PATH}/experience/cex-report`,
			title: 'sidenav.experience.reports.corp.kelly',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		})
	}

	if(env.IS_DNC_REPORT_ON){
		if(isToAllowDNCReport(companyId)){
			dashBoardNavTree.push({
				key: 'sidenav.experience.reports.corp.dnc',
				path: `${APP_PREFIX_PATH}/experience/dnc-report`,
				title: 'sidenav.experience.reports.corp.dnc',
				icon: '',
				iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
				breadcrumb: false,
				isRootMenuItem: false,				
				submenu: []
			})
		}
	}


	return dashBoardNavTree;
	
}

const isToAllowKellyReport = (companyId, userEmail) => {
	if(companyId && userEmail){
		const permissions = kellyReportPermissions[0];
		const agencyNumber = companyId ?? 0;
		return (((permissions?.allowedEmails?.includes(userEmail)) && (permissions?.agencyNumber === agencyNumber))  ) ? true : false;
	}

	return false;

}

const isToAllowDNCReport = (companyId) => {
	if(companyId){		
		const permissions = dncAllowedAgencies[0];		
		const agencyNumber = companyId ?? 0;		
		return (permissions?.allowedAgencies?.includes(agencyNumber)) ? true : false;
	}
	
	return false;
}