import UserService from "../../services/UserService";
import LocationsService from "../../services/Experience/CompanyGroup/LocationsService";
import AccountExperienceService from "../../services/Experience/SharedServices/AccountExperienceService";
import CallContactService from "services/Experience/CompanyGroup/CallContactService";
import ContactService from "../../services/Experience/Location/ContactService";
import QuickSight from "../../services/QuickSightService";
import GlobalConfigurationService from '../../services/Experience/SharedServices/GlobalConfigurationViewService';
import GraphService from "../../services/Experience/SharedServices/GraphService";
import DateConfigurations from '../../shared-logic/DateConfigurations';
import ReactPocSharedLogic from '../../shared-logic/ReactPocSharedLogic';
import { message } from "antd";

import {
  ON_COMPANY_LOCATIONS_REQUEST,
  ON_SELECTING_COMPANY_FROM_LOCATION,
  ON_SELECTING_LOCATION_VIEW_ID,
  ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN,
  ON_CONTACT_CORRECTIONS_REQUEST,
  ON_LOADING_LOCATIONS_VIEW,
  ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST,
  ON_LOADING_ANY_XM_PAGE_REQUEST,
  ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD,
  ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD,
  ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD,
  ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD,
  ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD,
  ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD,
  ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP,
  ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD,
  ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD,
  ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD,
  ON_SEARCHING_FOR_COMPANIES,
  ON_REQUESTING_DATE_CUSTOM_CONFIGURATION,
  ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER,
  ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC,
  ON_TESTING_REDUX,
  ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER,
  ON_CHANGING_CURRENT_LOCATION,
  ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL,
  ON_AWS_QS_URL_MOUNTED,
  ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS,
  ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST,
  ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY,
  ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD,
  ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS,
  ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC,
  ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD,
  ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC,
  ON_SETTING_NPS_OSAT_TABLE,  
  ON_SUBMITTING_GOAL_ACTION,
  ON_SELECTING_RECORD_TO_EDIT,
  ON_SELECTING_RECORD_KEY_TO_EDIT
} from '../constants/Experience';

export const onSearchingForCompanies = async (filteredAgencySearchList, searchText) => {
  return {
    type: ON_SEARCHING_FOR_COMPANIES,
    filteredAgencySearchList: filteredAgencySearchList,
    searchTerm: searchText
  }
}

export const onSelectingMoreInfoOnPercentageDashboard = async (selectedInnerIndex, isToDisplayInnerGraph) => {
  const isInnerGraphIndexSelected = (selectedInnerIndex >= 0) ? true : false;
  return {
    type: ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD,
    selectedInnerGraphIndex: selectedInnerIndex,
    isInnerGraphIndexSelected: isInnerGraphIndexSelected,
    isToDisplayPercentageInnerGraph: isToDisplayInnerGraph
  }
}

export const onRequestingGraphForLandingDashboard = async () => {
  const graph = await GraphService.getDashboardData();
  return {
    type: ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD,
    graphData: graph
  }
}

export const onSelectingRelationTypeInLandingDashboard = async (relationType) => {
  return {
    type: ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD,
    relationType: relationType
  }
}

export const onSelectingDateFrenquencyInLandingDashboard = async (dateType) => {
  return {
    type: ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD,
    selectedDateType: dateType
  }
}

export const onSelectingDownloadTypeInLandingDashboard = async (downloadType) => {
  return {
    type: ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD,
    selectedDownloadType: downloadType
  }
}

export const onSettingActiveTabInAnyNativeDashboard = async (tab) => {
  return {
    type: ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD,
    pocActiveTab: tab
  }
}

export const onSettingActiveTabForSurveysDashboards = async (tab) => {
  return {
    type: ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS,
    surveysActiveTab: tab
  }
}

export const onRequestingInformationForCompanyGroup = async (companyId) => {
  const companyGroupUuid = await AccountExperienceService.getCompanyGroupUuid(UserService.getToken(), companyId, "onRequestingInformationForCompanyGroup");
  return {
    type: ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP,
    companyGroupUUID: companyGroupUuid
  }
}

export const onRequestingDateCustomConfiguration = async () => {
  let customDateRanges = [];
  const cachedDateRanges = UserService.getUserExclusiveDateRanges();
  if (cachedDateRanges?.length > 0) {
    customDateRanges = [...cachedDateRanges];
  } else {
    const token = await UserService.getToken();
    // For now Bayada, we will expand this concept when business allocates more time
    customDateRanges = await AccountExperienceService.getBayadaExclusiveDateRanges(token, "onRequestingDateConfigurationForBayada");
    UserService.setUserExclusiveDateRanges(customDateRanges);
  }

  return {
    type: ON_REQUESTING_DATE_CUSTOM_CONFIGURATION,
    customDateRanges: customDateRanges
  }
}


export const onRequestingLandingDashboardsForCompanySync = async (startDate, endDate, dateType, contactRelationType, companyId, groupId, hideMessagePromise) => {
  // Company Sync customDateRanges,
  const token = await UserService.getToken();
  const customDateRanges = await getExclusiveDateRanges(token, "onRequestingLandingDashboardsForCompanySync");
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "start"));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "end"));

  const landingCompanySyncDashboardData = await AccountExperienceService.getCompanySyncNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingLandingDashboardsForCompanySync");
  const parentIden = await AccountExperienceService.getParentCompanyGroupUuidFromCompanySyncId(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), "onRequestingLandingDashboardsForCompanySync");
  const landingCompanyGroupDashboardData = await AccountExperienceService.getCompanyGroupNpsAndOsatDataForBayadaCorp(token, startingDate, endingDate, dateType, contactRelationType, parentIden, "onRequestingLandingDashboardsForCompanySync");
  const industryDashboardData = await AccountExperienceService.getIndustryNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanySync");
  const landingCompanyGroupSyncDashboardData = await AccountExperienceService.getCompanyGroupSyncNpsAndOsatDataForCompanySync(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanySync");
  const landingOsatResultsByTagIdForCompanySync = await AccountExperienceService.getCompanyResultsByTagIdForCompanySync(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingLandingDashboardsForCompanySync");
  const landingOsatTagResultsForCompanyGroupSyncAndIndustry = await AccountExperienceService.getCompanyGroupSyncResultsTagIds(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingLandingDashboardsForCompanySync");
  const landingOsatTagResultsForCompanyGroup = await AccountExperienceService.getTagIdResultsForCompanyGroup(token, startingDate, endingDate, dateType, contactRelationType, parentIden, "onRequestingLandingDashboardsForCompanySync");
  const allSurveyResponses = await AccountExperienceService.getAllQuestionsForGivenCompany(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingLandingDashboardsForCompanySync");
  const companySyncOsatNpsGoals = await AccountExperienceService.getCompanySyncNpsAndOsatGoals(token, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), "onRequestingLandingDashboardsForCompanySync");

  if (hideMessagePromise) {
    hideMessagePromise = await message.loading({ content: "Got the data! let me process it", key: "updatable" });
  }

  const isCompanyGroup = await UserService.getSelectedAgency()?.IsCompanyGroup;
  const reports = await ReactPocSharedLogic.handleSurveyResponseConversion(allSurveyResponses, companyId, isCompanyGroup, (groupId ?? await UserService.getSelectedAgency()?.GroupId));
  const allSurveyResponsesConfiguration = await ReactPocSharedLogic.handleInternalConfigurationForSurveyResponses(allSurveyResponses);


  if (hideMessagePromise) {
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 0.1 });
  }

  return {
    type: ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD,
    landingCompanySyncDashboardData: landingCompanySyncDashboardData,
    landingCompanyGroupDashboardData: landingCompanyGroupDashboardData,
    landingCompanyGroupSyncDashboardData: landingCompanyGroupSyncDashboardData,
    industryDashboardData: industryDashboardData,
    landingOsatResultsByTagIdForCompanySync: landingOsatResultsByTagIdForCompanySync,
    landingOsatTagResultsForCompanyGroupSyncAndIndustry: landingOsatTagResultsForCompanyGroupSyncAndIndustry,
    landingOsatTagResultsForCompanyGroup: landingOsatTagResultsForCompanyGroup,
    allSurveyResponsesConfiguration: allSurveyResponsesConfiguration,
    startSelectedDate: startingDate,
    endSelectedDate: endingDate,
    companySyncOsatNpsGoals: companySyncOsatNpsGoals,
    preparedSurveyResponses: {
      surveysReport: reports?.surveysReport,
      allQuestionsReport: reports?.allQuestionsReport
    }
  }
}


export const onRequestingLandingDashboardsForCompanyGroup = async (startDate, endDate, dateType, contactRelationType, companyId, groupId, hideMessagePromise) => {
  // Company Group
  const token = await UserService.getToken();
  const customDateRanges = await getExclusiveDateRanges(token, "onRequestingLandingDashboardsForCompanyGroup");
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "start"));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "end"));

  const landingDashboardCompanyGroupData = await AccountExperienceService.getNpsAndOsatDataForCompanyGroups(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroup");
  const landingIndustryDashboardData = await AccountExperienceService.getIndustryNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroup");
  const landingCompanyGroupOsatResultsByTagId = await AccountExperienceService.getCompanyGroupTagIdsResults(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingLandingDashboardsForCompanyGroup")
  const landingOsatTotalResultsByIndustryForCompanyGroup = await AccountExperienceService.getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingLandingDashboardsForCompanyGroup");
  const landingOsatCompanyResultsByTagId = await AccountExperienceService.getOverallOsatTagResultsByCompaniesUnderCompanyGroup(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingLandingDashboardsForCompanyGroup");
  const companyUUID = await AccountExperienceService.getCompanyGroupUuid(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), "getCompanyResultsByTagIdForCompanyGroup");
  const companyGroupOsatNpsGoals = await AccountExperienceService.getNpsAndOsatGoalsForCompanyGroupsByUUID(token, companyUUID, contactRelationType, "onRequestingLandingDashboardsForCompanyGroup");

  // IMPROVE TODO
  if (hideMessagePromise) {
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 0.1 });
  }

  console.warn("landingOsatTotalResultsByIndustryForCompanyGroup", landingOsatTotalResultsByIndustryForCompanyGroup);

  return {
    type: ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD,
    landingCompanyGroupDashboardData: landingDashboardCompanyGroupData,
    industryDashboardData: landingIndustryDashboardData,
    landingOsatResultsByTagIdForCompanyGroup: landingCompanyGroupOsatResultsByTagId,
    landingOsatTotalResultsByIndustryForCompanyGroup: landingOsatTotalResultsByIndustryForCompanyGroup,
    landingOsatCompanyResultsByTagId: landingOsatCompanyResultsByTagId,
    companyGroupOsatNpsGoals: companyGroupOsatNpsGoals,
    startSelectedDate: startingDate,
    endSelectedDate: endingDate,
  }
}

export const onRequestingLandingDashboardsForCompanyGroupSync = async (startDate, endDate, dateType, contactRelationType, groupId, hideMessagePromise) => {
  // Company Group Sync
  const token = await UserService.getToken();
  const customDateRanges = await getExclusiveDateRanges(token, "onRequestingLandingDashboardsForCompanyGroupSync");
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "start"));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "end"));

  const landingCompanyGroupSyncDashboardData = await AccountExperienceService.getCompanyGroupSyncNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync");
  const landingIndustryDashboardData = await AccountExperienceService.getIndustryNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync");
  const landingOsatResultsByTagIdForCompanyGroupSync = await AccountExperienceService.getCompanyResultsByTagIdForCompanyGroupSync(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync");
  const landingOsatTotalResultsByIndustryForCompanyGroupSync = await AccountExperienceService.getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync(token, startingDate, endingDate, dateType, contactRelationType, "onRequestingLandingDashboardsForCompanyGroupSync");
  const companyGroupSyncOsatNpsGoals = await AccountExperienceService.getNpsAndOsatGoalsForCompanyGroupsByUUID(token, "c47b82c2-35c1-11eb-9010-4b3000001618" , contactRelationType, "onRequestingLandingDashboardsForCompanyGroup");
  
  if (hideMessagePromise) {
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 0.1 });
  }

  return {
    type: ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD,
    landingCompanyGroupSyncDashboardData: landingCompanyGroupSyncDashboardData,
    industryDashboardData: landingIndustryDashboardData,
    landingOsatResultsByTagIdForCompanyGroupSync: landingOsatResultsByTagIdForCompanyGroupSync,
    landingOsatTotalResultsByIndustryForCompanyGroupSync: landingOsatTotalResultsByIndustryForCompanyGroupSync,
    companyGroupSyncOsatNpsGoals: companyGroupSyncOsatNpsGoals,
    startSelectedDate: startingDate,
    endSelectedDate: endingDate
  }
}

const getExclusiveDateRanges = async (token, whoIsCalling) => {
  const cachedCustomedDateRanges = UserService.getUserExclusiveDateRanges();
  if (cachedCustomedDateRanges?.length > 0) {
    return cachedCustomedDateRanges;
  }

  const customDateRanges = await AccountExperienceService.getBayadaExclusiveDateRanges(token, whoIsCalling);
  UserService.setUserExclusiveDateRanges(customDateRanges);
  return customDateRanges;

}

export const onRequestingSurveyResponsesForCompanyGroup = async (startDate, endDate, dateType, contactRelationType, companyId, groupId, hideMessagePromise, wasAnAgencyChanged) => {
  let allSurveyResponses;
  let allCompanyParentIds;
  let reports;

  const token = await UserService.getToken();
  const customDateRanges = await getExclusiveDateRanges(token, "onRequestingSurveyResponsesForCompanyGroup");

  // if passed date is undefined then get a default
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? await UserService.getSelectedAgency()?.GroupId), "start", 0));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? await UserService.getSelectedAgency()?.GroupId), "end"));

  const cachedStartDate = await UserService.getSurveyResultsStartDate();
  const cachedEndDate = await UserService.getSurveyResultsEndDate();

  if (DateConfigurations.isSameDate(startingDate, cachedStartDate) && DateConfigurations.isSameDate(endingDate, cachedEndDate) && !wasAnAgencyChanged) {
    // Get from Cache
    reports = await UserService.getUserSurveyResults();
  } else {
    // Fetch data
    allSurveyResponses = await AccountExperienceService.getAllQuestionsForCompaniesUnderGivenCompanyGroup(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingSurveyResponsesForCompanyGroup");
    allCompanyParentIds = await AccountExperienceService.getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps(token, startingDate, endingDate, dateType, contactRelationType, (companyId ?? UserService.getSelectedAgency()?.CompanyId), (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingSurveyResponsesForCompanyGroup");
    
    if (hideMessagePromise) {
      hideMessagePromise = await message.loading({ content: "Got the data! let me process it", key: "updatable" });
    }

    // Merging requests for the fastes not best solution for this.
    const isCompanyGroup = await UserService.getSelectedAgency()?.IsCompanyGroup;
    const mergeSurveysResponses = await ReactPocSharedLogic.getMergedObjectArrays(allSurveyResponses, allCompanyParentIds);

    reports = await ReactPocSharedLogic.handleSurveyResponseConversion(mergeSurveysResponses, companyId, isCompanyGroup, (groupId ?? await UserService.getSelectedAgency()?.GroupId));

    // Cache
    UserService.setSurveyResultsStartDate(startingDate);
    UserService.setSurveyResultsEndDate(endingDate);
    UserService.setUserSurveyResults(reports);
  }

  if (hideMessagePromise) {
    // Leave duration 1 as it frequent and fast change makes it so that it waits for the process to wrap up for locations in particular
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 1 });
  }


  return {
    type: ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC,
    preparedSurveyResponses: {
      surveysReport: reports?.surveysReport,
      allQuestionsReport: reports?.allQuestionsReport
    },
    startSelectedDate: startingDate,
    endSelectedDate: endingDate
  }
}

export const onRequestingClientReportDashboardForCompany = async (companyId) => {
  // Company
  const token = await UserService.getToken();
  // FOR DW
  //const rawClientList = await AccountExperienceService.getListOfClientsForCompany(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), "onRequestingClientReportDashboardForCompany");
  // const clientList = await ReactPocSharedLogic.handleInternalConfigurationForClientsListForCompany(rawClientList);
  
  // FOR Vantage
  const rawClientList = await AccountExperienceService.getListOfClientsForCompanyFromVantage(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), "onRequestingClientReportDashboardForCompany");
  const clientList = await ReactPocSharedLogic.handleInternalConfigurationForClientsListForVantageCompany(rawClientList);
  return {
    type: ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY,
    clientsReportConfiguration: clientList
  }
}

export const onRequestingCexReportForCompanyGroupSync = async (startDate, endDate, hideMessagePromise) => {
  let preparedCexResponses;
  const token = await UserService.getToken();
  const customDateRanges = await getExclusiveDateRanges(token, "onRequestingCexReportForCompanyGroupSync");

  // if passed date is undefined then get a default
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, (await UserService.getSelectedAgency()?.GroupId), "start", 0));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, (await UserService.getSelectedAgency()?.GroupId), "end"));

  const cachedStartDate = await UserService.getSurveyResultsStartDate();
  const cachedEndDate = await UserService.getSurveyResultsEndDate();

  if (DateConfigurations.isSameDate(startingDate, cachedStartDate) && DateConfigurations.isSameDate(endingDate, cachedEndDate)) {
    // Get from Cache
    preparedCexResponses = await UserService.getUserCexResults();
  } else {
    const rawCexList = await AccountExperienceService.getCexReportDataForCompanyGroupSync(token, startingDate, endingDate, "onRequestingCexReportForCompanyGroupSync");

    if (hideMessagePromise) {
      hideMessagePromise = await message.loading({ content: "Got the data! let me process it", key: "updatable" });
    }

    preparedCexResponses = await ReactPocSharedLogic.handleSurveysForCEXReport(rawCexList);

    UserService.setSurveyResultsStartDate(startingDate);
    UserService.setSurveyResultsEndDate(endingDate);
    UserService.setUserCexResults(preparedCexResponses);
  }

  if (hideMessagePromise) {
    // Leave duration 1 as it frequent and fast change makes it so that it waits for the process to wrap up for locations in particular
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 1 });
  }
  
  return {
    type: ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC,
    preparedCexResponses: preparedCexResponses
  }
}

export const onRequestingDncReportForCompanyGroupSync = async (companyId, startDate, endDate, hideMessagePromise) => {
  let preparedDncResponses;
  const token = await UserService.getToken();
  const customDateRanges = await getExclusiveDateRanges(token, "onRequestingDncReportForCompanyGroupSync");

  // if passed date is undefined then get a default
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, (await UserService.getSelectedAgency()?.GroupId), "start", 0));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, (await UserService.getSelectedAgency()?.GroupId), "end"));

  const cachedStartDate = await UserService.getSurveyResultsStartDate();
  const cachedEndDate = await UserService.getSurveyResultsEndDate();

  if (DateConfigurations.isSameDate(startingDate, cachedStartDate) && DateConfigurations.isSameDate(endingDate, cachedEndDate)) {
    // Get from Cache
    preparedDncResponses = await UserService.getUserDncResults();
  } else {
    const rawDncList = await CallContactService.getDncListForCompanyGroup(token, (companyId ?? UserService.getSelectedAgency()?.CompanyId), startingDate, endingDate, "onRequestingDncReportForCompanyGroupSync");

    preparedDncResponses = await ReactPocSharedLogic.handleSurveysForDncReport(rawDncList);

    UserService.setSurveyResultsStartDate(startingDate);
    UserService.setSurveyResultsEndDate(endingDate);
    UserService.setUserDncResults(preparedDncResponses);
  }
  
  return {
    type: ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC,
    preparedDncResponses: preparedDncResponses
  }
}

export const onSubmittingDateRangeForLandingDashboards = async (startSelectedDate, endSelectedDate) => {

  return {
    type: ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD,
    startSelectedDate: startSelectedDate,
    endSelectedDate: endSelectedDate
  }
}

export const onSavingUnformattedDateRangeFromDatePicker = async (unformattedStartSelectedDate, unformattedEndSelectedDate) => {

  return {
    type: ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER,
    unformattedStartSelectedDate: unformattedStartSelectedDate,
    unformattedEndSelectedDate: unformattedEndSelectedDate
  }
}

export const onTestingRedux = async (testingRedux) => {

  return {
    type: ON_TESTING_REDUX,
    reduxTest: testingRedux
  }
}

export const onRequestingFiltersForLandingDashboards = async () => {
  const token = await UserService.getToken();
  const contactRelationFilters = await AccountExperienceService.getContactRelationOptionsFilters(token, "onRequestingFiltersForLandingDashboards");
  const dateRangeFilters = await AccountExperienceService.getDateRangeOptionsFilters(token, "onRequestingFiltersForLandingDashboards");

  return {
    type: ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD,
    contactRelationOptions: contactRelationFilters,
    dateRangeOptions: dateRangeFilters
  }
}

export const onLoadingAnyXMpageRequest = async (pagelocation, companyId, companyGroup, companyGroupIds) => {
  let configurationDisplaySettingsPerPage = {};

  const singleCompanyGroup = [companyGroup];
  const companyGroups = companyGroupIds ? companyGroupIds : [];
  const combinedcompanyGroupIds = companyGroups?.concat(singleCompanyGroup);


  configurationDisplaySettingsPerPage = await GlobalConfigurationService.getViewConfigurationPermissions(pagelocation, companyId, (combinedcompanyGroupIds ?? []));
  return {
    type: ON_LOADING_ANY_XM_PAGE_REQUEST,
    configurationDisplaySettingsPerPage: configurationDisplaySettingsPerPage
  }
}

export const onChangingCurrentLocation = async (location) => {
  return {
    type: ON_CHANGING_CURRENT_LOCATION,
    currentLocation: location
  }
}

export const onContactCorrectionsRequest = async (contactType) => {
  const contactCorrectionsByContactType = await ContactService.getAllCorrectionsByContactType(UserService.getToken(), contactType, "onContactCorrectionsRequest");

  return {
    type: ON_CONTACT_CORRECTIONS_REQUEST,
    correctionsByContactType: contactCorrectionsByContactType
  }
}

export const onFetchingLocationsList = async () => {
  const agencyLocationsByUsername = await LocationsService.getAllLocationsOfCompanyGroupByEmailId(UserService.getToken(), "onLocationsPageLoad");
  const searchableLocationsList = await ReactPocSharedLogic.transformRawCompanyGroupAndChildrenInSearchableResultSet(agencyLocationsByUsername);

  return {
    type: ON_COMPANY_LOCATIONS_REQUEST,
    agencyLocationsList: agencyLocationsByUsername,
    searchableLocationsList: searchableLocationsList
  }
}

export const onSettingFilteredCompanyGroupOptions = async (companyGroupFilteredOptions) => {
  return {
    type: ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS,
    companyGroupFilteredOptions: companyGroupFilteredOptions
  }

}

export const onRequestingNPSandOSATGoals = async (startDate, endDate, dateType, contactRelationType, groupId) => {
  const token = await UserService.getToken();
  const customDateRanges = await getExclusiveDateRanges(token, "onRequestingNPSandOSATGoals");
  const startingDate = (startDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "start"));
  const endingDate = (endDate ?? DateConfigurations.defaultDate(customDateRanges, (groupId ?? UserService.getSelectedAgency()?.GroupId), "end"));
  
  const { agencyLocationsList } = await onFetchingLocationsList();
  let activeCompanyWithCompanyGroupList = await ReactPocSharedLogic.transformRawCompanyGroupAndChildrenForGoalsTable(agencyLocationsList);
  const { companyGroupIdsString, companiesSyncIdsString } = await ReactPocSharedLogic.getGroupIdsAndOptionsString(activeCompanyWithCompanyGroupList);
  // Fetch all data
  const companySyncOsatNpsData = await AccountExperienceService.getCompanySyncNpsAndOsatData(token, startingDate, endingDate, dateType, contactRelationType, companiesSyncIdsString, (groupId ?? UserService.getSelectedAgency()?.GroupId), "onRequestingNPSandOSATGoals");
  const companyGroupOsatNpsData = await AccountExperienceService.getNpsAndOsatDataForCompanyGroupsByUUID(token, companyGroupIdsString, startingDate, endingDate, dateType, contactRelationType, "onRequestingNPSandOSATGoals");  
  const companySyncOsatNpsGoals = await AccountExperienceService.getCompanySyncNpsAndOsatGoals(token, contactRelationType, companiesSyncIdsString, "onRequestingNPSandOSATGoals");
  const companyGroupOsatNpsGoals = await AccountExperienceService.getNpsAndOsatGoalsForCompanyGroupsByUUID(token, companyGroupIdsString, contactRelationType, "onRequestingNPSandOSATGoals");
  // For Goals
  let allCompaniesAndCompanyGroupOsatandNpsGoalsCombined = [];
  allCompaniesAndCompanyGroupOsatandNpsGoalsCombined = allCompaniesAndCompanyGroupOsatandNpsGoalsCombined?.concat(companySyncOsatNpsGoals, companyGroupOsatNpsGoals);
  // For data
  let allCompaniesAndCompanyGroupOsatandNpsDataCombined = [];
  allCompaniesAndCompanyGroupOsatandNpsDataCombined = allCompaniesAndCompanyGroupOsatandNpsDataCombined?.concat(companySyncOsatNpsData, companyGroupOsatNpsData);
  // Combine 
  activeCompanyWithCompanyGroupList = await ReactPocSharedLogic.getCompanyGroupNpsandOsat(allCompaniesAndCompanyGroupOsatandNpsDataCombined, allCompaniesAndCompanyGroupOsatandNpsGoalsCombined, activeCompanyWithCompanyGroupList);
  const tableItems = await ReactPocSharedLogic.handleCompanyCompanyGroupGoalsReport(activeCompanyWithCompanyGroupList);

    // const flattenedArray
    console.log("---------mmm");
    console.log(allCompaniesAndCompanyGroupOsatandNpsGoalsCombined);
    console.log(allCompaniesAndCompanyGroupOsatandNpsDataCombined);
    console.log(activeCompanyWithCompanyGroupList);

 

  return {
    type: ON_SETTING_NPS_OSAT_TABLE,
    activeCompanyWithCompanyGroupList: tableItems
  }
}


export const onSubmittingGoalActionForSelectedCompanyRecord = async(updatedGoalRecordTableObjectData, selectedRecord, goalObject, values, childrenGoalObjects) => {
  const token = await UserService.getToken();
  let goalSubmittion;
  let childrenGoalSubmittion;
  if(selectedRecord?.isCompanyGroup){
    goalSubmittion = await AccountExperienceService.postNpsAndOsatGoalsForCompanyGroupsByUUID(token, goalObject, goalObject?.CompanyGroupId, null, "onSubmittingGoalActionForSelectedCompanyRecord");
    if(values?.isToPropagateToChildren && childrenGoalObjects?.length > 0){
      childrenGoalSubmittion = await AccountExperienceService.postCompanySyncNpsAndOsatGoals(token, childrenGoalObjects, childrenGoalObjects[0]?.CompanyId, null, "onSubmittingGoalActionForSelectedCompanyRecord");
    }
  }else{
    goalSubmittion = await AccountExperienceService.postCompanySyncNpsAndOsatGoals(token, goalObject, goalObject?.CompanyId, null, "onSubmittingGoalActionForSelectedCompanyRecord");
  }
  
  return {
    type: ON_SUBMITTING_GOAL_ACTION,
    updatedGoalRecordTableObjectData: updatedGoalRecordTableObjectData,
    selectedRecordKey: selectedRecord?.key,
    goalSubmittion: goalSubmittion,
    childrenGoalSubmittion: childrenGoalSubmittion
  }
}

export const onSelectingRecordToEdit = async(record) => {
  return {
    type: ON_SELECTING_RECORD_TO_EDIT,
    selectedRecord: record
  }
}


export const onSelectingRecordKeyToEdit = async(selectedEditingRecordKey) => {
  return {
    type: ON_SELECTING_RECORD_KEY_TO_EDIT,
    selectedEditingRecordKey: selectedEditingRecordKey 
  }
}

export const onCompanySelectionFromLocationsList = async (isLocationSelected) => {
  return {
    type: ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST,
    isLocationSelected: isLocationSelected
  }

}

export const onSelectingCompanyFromLocationsList = async (selectedAgencyFromLocationsList) => {
  return {
    type: ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST,
    selectedAgencyFromLocationsList: selectedAgencyFromLocationsList
  }

}

export const onLoadingLocationsList = async (lighterActiveLocationsList) => {
  return {
    type: ON_LOADING_LOCATIONS_VIEW,
    lighterActiveLocationsList: lighterActiveLocationsList
  }
}

export const onSelectingCompanyFromLocationView = async (companyName, companyKey, companyPath) => {
  return {
    type: ON_SELECTING_COMPANY_FROM_LOCATION,
    selectedCompanyNameFromLocationView: companyName,
    selectedCompanyKeyFromLocationView: companyKey,
    selectedCompanyPathFromLocationView: companyPath
  }
}

export const onSelectingLocationViewId = async (dashboardId) => {
  return {
    type: ON_SELECTING_LOCATION_VIEW_ID,
    selectedDashboardId: dashboardId
  }
}

export const onRequestingReportForLocationFromDropdown = async (selectedCompanyDataFromLocationsDropdown) => {
  return {
    type: ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN,
    selectedLocationCompanyId: selectedCompanyDataFromLocationsDropdown?.CompanyId,
    selectedLocationGroupId: selectedCompanyDataFromLocationsDropdown?.GroupId,
    isSelectedACompanyGroup: selectedCompanyDataFromLocationsDropdown?.IsCompanyGroup
  }
}

const onRequestingAwsArn = async (token, hideMessagePromise) => {
  let userAwsArn = UserService.getAwsUserArn();
  if (!(userAwsArn)) {
    if (hideMessagePromise) {
      hideMessagePromise = await message.loading({ content: "Configuring your account for new Report Experience", key: "updatable" });
    }
    userAwsArn = await QuickSight.getUserAwsArn(token ?? UserService.getToken(), "onRequestingAwsArn");
    if (userAwsArn) {
      UserService.setAwsUserArn(userAwsArn);
    } else {
      userAwsArn = null;
    }
  }

  return userAwsArn
}

export const onRequestingQuicksightReportsForUser = async (location, token, hideMessagePromise, companyId) => {
  const selectedAgency = await UserService.getSelectedAgency();
  let embeddedUrls = [];
  const userAwsArn = await onRequestingAwsArn((token ?? UserService.getToken()), hideMessagePromise);
  let dashboardIds = await GlobalConfigurationService.getQuicksightDashboardIdsByLocation(location);

  if (hideMessagePromise) {
    hideMessagePromise = await message.loading({ content: "Setting up dashboards, just a sec", key: "updatable" });
  }
  embeddedUrls = await QuickSight.getEmbeddedReportUrlForRegisteredUser((token ?? UserService.getToken()), (userAwsArn ?? UserService.getAwsUserArn()), dashboardIds, (companyId ?? selectedAgency?.CompanyId), "onRequestingQuicksightReportsForUser");

  if (hideMessagePromise) {
    hideMessagePromise = await message.success({ content: "Ready!", key: "updatable", duration: 1 });
  }
  return {
    type: ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER,
    quicksight: {
      embeddedUrls: embeddedUrls,
      embeddingLocation: location,
      dashboardIds: dashboardIds
    }
  }
}


export const onEmbeddingQuicksightReport = async (embeddedLocation, isUrlAlreadyEmbeddedInLocation) => {
  return {
    type: ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL,
    quicksightEmbeddedConfiguration: {
      embeddedLocation: embeddedLocation,
      isUrlAlreadyEmbeddedInLocation: isUrlAlreadyEmbeddedInLocation
    }
  }
}

export const onQuicksightUrlReportEmbedded = async (isUrlAlreadyEmbeddedInLocation) => {
  return {
    type: ON_AWS_QS_URL_MOUNTED,
    isAwsQsUrlAlreadyEmbedded: isUrlAlreadyEmbeddedInLocation
  }
}