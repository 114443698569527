import {redirectUserTabtoVantageReviewManager} from "../../services/ReviewManagerService";
import {getRefreshedEfrontLoginUrl, mapTrainingCredentials} from "../../services/EfrontService";
import UserService from "../../services/UserService";
import FeatureLoggingService from "../../services/FeatureLoggingService";
import $ from 'jquery'; 
import mainNavigationConfig from "../../configs/MainNavigationConfig";
import ReviewManagerService from 'services/ReviewManagerService';


import { 
  GET_EFRONT_REFRESHED_URL,
  IS_SERVICE_AVAILABLE_FOR_USER,
  IS_REVIEW_MANAGER_AVAILABLE_FOR_USER,
  GET_VANTAGE_WEB_USER_ACCOUNTS,
  GET_SELECTED_VANTAGE_WEB_ACCOUNT_DATA,
  ON_SELECTING_A_COMPANY_FROM_MULTI_COMPANY_GROUP_ACCOUNT,
  GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_CLICK,
  REDIRECT_VANTAGE_VERSION_OF_REVIEW_MANAGER,
  IS_HCP_ADMIN,
  IS_THERE_REQUESTED_SERVICE,
  GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_LOAD,
  IS_USERS_PAGE_CLICKED_ON,
  GET_EXPERT_CALL_TO_ACTION_OBJECT,
  DISPLAY_TRAINING_LOGIN,
  SUBMIT_TRAINING_CREDENTIALS,
  IS_TRAINING_SERVICE_AVAILABLE_FOR_USER,
  LOG_FEATURE_USER_INTERACTION,
  RESET_QUICKSIGHT_URL,
  DISPLAY_HUBSPOT_FORM_DEMO_REQUEST
} from "../constants/Cip";

const isSpecialAdmin = () => {
  return UserService.isUserSpecialHCPAdmin();
}


export const resetQuickSightUrl = async () => {    
  return {
    type: RESET_QUICKSIGHT_URL,
    quickSightRequestedUrl: null
  }
}


export const logFeatureUserInteraction = async (featureUsed, featureValue, featureLocation) => { 
  const selectedAgency = await UserService.getSelectedAgency();  
  const wasLoggingSuccesful = await FeatureLoggingService.logFeatureInteraction(
    UserService.getToken(),    
    selectedAgency?.CompanyId, 
    featureUsed,
    featureValue,    
    featureLocation    
    )
  
  return {
    type: LOG_FEATURE_USER_INTERACTION,
    wasLoggingSuccesful: wasLoggingSuccesful
  }
}

export const getCallToActionAgentInfo = async (serviceName) => {
  const currentAgency = await UserService.getSelectedAgency();
  const currentAgencyId = ($.isEmptyObject(currentAgency)) ? -1 : currentAgency?.CompanyId;
  const agentInfo = UserService.getCallToActionAgent(currentAgencyId, serviceName);
  
  return {
    type: GET_EXPERT_CALL_TO_ACTION_OBJECT,
    expertObject: agentInfo
  }
}

export const setUserPageClickedOnFlag = async(isClicked) => {  
  const isPageClicked = isClicked;
  return {
    type: IS_USERS_PAGE_CLICKED_ON,    
    isUsersPageClicked: isPageClicked
  }
}

export const isSignedUserAnHcpAdmin = async() => {
  return {
    type: IS_HCP_ADMIN,
    isHcpAdmin: isSpecialAdmin()
  }
}

export const getServiceRequested = async() => { 
  // Handle it this way since the react life cycle will not wait for the async results to update the DOM on time 
  var urlParams = new URLSearchParams(window.location.search);
  const isUserComingFromEL = urlParams.get('redirectFromEL');
  const requestedService = urlParams.get('service');  
  return {
    type: IS_THERE_REQUESTED_SERVICE,
    requestedService: requestedService,
    isUserComingFromEL: isUserComingFromEL
  }
}

export const isServiceAvailableForUser = async(serviceName) => {  
  let mainNav = mainNavigationConfig;
  let isServiceAvailable = false;
  mainNav.forEach(function(service){      
    if(service.key === serviceName){
      isServiceAvailable = service.isServiceAvailableForUser;
      if(isServiceAvailable){
        //setVantageAgency();
      }
    }
  });

  return {
    type: IS_SERVICE_AVAILABLE_FOR_USER,
    isSubscribedToService: isServiceAvailable
  }
};

export const isTrainingServiceAvailableForUser = async(serviceName) => {
  //TODO fix the update cycle of Redux to not be behind x1, right now I'll have this method for training only  
  let mainNav = mainNavigationConfig;  
  let isServiceAvailable = false;
  mainNav.forEach(function(service){      
    if(service.key === serviceName){
      isServiceAvailable = service.isServiceAvailableForUser;
    }
  });

  return {
    type: IS_TRAINING_SERVICE_AVAILABLE_FOR_USER,
    isSubscribedToTraining: isServiceAvailable
  }
};

export const isRMServiceAvailableForUser = async(serviceName) => {  
  const selectedAgency = await UserService.getSelectedAgency();
  let isRMAvailableForTheSelectedAccount = false;

  if((selectedAgency?.CompanyId !== null || selectedAgency?.CompanyId > 0) && selectedAgency?.CompanyId !== 1483){   
    isRMAvailableForTheSelectedAccount = await ReviewManagerService.isAccountSubscribedToReviewManager(selectedAgency?.CompanyId, UserService.getToken(), UserService.getUsername());
    selectedAgency[`isSubscribedToReviewManager`] = isRMAvailableForTheSelectedAccount ? true : false;
  }else if(isSpecialAdmin()){
    isRMAvailableForTheSelectedAccount = true;
  }else{
    console.log("Unavailable RM service");
    isRMAvailableForTheSelectedAccount = false;
  }
  
  return {
    type: IS_REVIEW_MANAGER_AVAILABLE_FOR_USER,
    isSubscribedToRM: isRMAvailableForTheSelectedAccount
  }
};

export const redirectToVantageReviewManager = async() => {      
  const selectedCompany = await UserService.getSelectedAgency();
  await redirectUserTabtoVantageReviewManager($.isEmptyObject(selectedCompany) && isSpecialAdmin() ? 1483 : selectedCompany.CompanyId, isSpecialAdmin(), UserService.getToken());
  return {
    type: REDIRECT_VANTAGE_VERSION_OF_REVIEW_MANAGER,
    selectedAgency:selectedCompany.CompanyId
  }
}

export const getEfrontUrl = async() => {
  // Log out first before requesting a new URL
  // await logoutFromTraining();
  const updatedUrl = await getRefreshedEfrontLoginUrl();
  let urlTimesFetched = 0;
  let isSubscribed = false;
  if (updatedUrl) {
    urlTimesFetched++;
    isSubscribed = true;
  }

  
  return {
    type: GET_EFRONT_REFRESHED_URL,
    url: updatedUrl,
    timesFeched: urlTimesFetched, 
    isSubscribedToElearning: isSubscribed 
  } 
};

export const getWebVantageUsers = async() => {
  const allWebAccounts = await UserService.getWebAccounts();
  let isDefaultAccount = false;
  allWebAccounts?.forEach(function(webAccount){
    if(webAccount.IsSelectedAgency){
      isDefaultAccount = true;
    }
  });
  return {
    type: GET_VANTAGE_WEB_USER_ACCOUNTS,
    userWebAccounts: allWebAccounts,
    totalNumberOfWebAccounts:allWebAccounts?.length,
    isThereASelectedAccount: isDefaultAccount
  }
}

export const onSelectingACompanyFromMultiCompanyGroupAccount = async(selectedCompany) => {
  const webAccounts = await UserService.getWebAccounts();
  let companyHasBeenSelected = false;

  // TODO maybe here you can wire the change of menus from default to corp  
  if(selectedCompany){
    webAccounts.forEach(function(webAccount){        
      if(webAccount.CompanyId === selectedCompany?.CompanyId){                      
        webAccount.IsSelectedAgency = true;        
        companyHasBeenSelected = true;
        console.log("companyHasBeenSelected", companyHasBeenSelected);
      }
    });
    UserService.setMenuSettingsBasedOnTypeOfAccount(selectedCompany);
  }else{    
    selectedCompany = webAccounts[0];
  }

  // Save for now on general session 
  UserService.setSelectedAgency(selectedCompany);

  return {
    type: ON_SELECTING_A_COMPANY_FROM_MULTI_COMPANY_GROUP_ACCOUNT,
    selectedWebCompany: selectedCompany,
    companyHasBeenSelected: companyHasBeenSelected
  }
}

export const toggleSelectedUpperNavigationTabOnClick = async(service) => {  

  mainNavigationConfig.forEach(function(mainNavMenu){
		if(mainNavMenu.current === true ){
			mainNavMenu.current = false;
		}
	})

	if(service.current === true){
		service.current = false;
	}else{
		service.current = true;
	}

  return {
    type: GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_CLICK,
    service:service
  }
}

export const toggleSelectedUpperNavigationTabOnLoad = async(path) => {
  const pathArray = path.split('/');
  const serviceOnLoad = pathArray[2];

  if(serviceOnLoad){
    mainNavigationConfig.forEach(function(mainNavMenu){
      if(mainNavMenu?.current === true ){
        mainNavMenu.current = false;
      }
      if(mainNavMenu?.key === serviceOnLoad && mainNavMenu?.current === false){
        mainNavMenu.current = true;
      }
    })
  }
  
  return {
    type: GET_SELECTED_SERVICE_FROM_UPPER_NAV_ON_LOAD,
    serviceOnLoad:serviceOnLoad
  }
}

export const getSelectedVantageCompanyData = async() => {  
  const selectedCompany = await UserService.getSelectedAgency();

  return {
    type: GET_SELECTED_VANTAGE_WEB_ACCOUNT_DATA,
    selectedAgency:selectedCompany
  }
}

export const displayTrainingLoginModal = async(isToDisplay) => {
  return {
    type: DISPLAY_TRAINING_LOGIN,
    isTodisplayTrainingLoging: isToDisplay
  }
}

export const displayHubspotFormDemoRequest = async(isToDisplay) => {
  return {
    type: DISPLAY_HUBSPOT_FORM_DEMO_REQUEST,
    isTodisplayHubspotFormDemoRequest: isToDisplay
  }
}

export const submitTrainingCredentials = async(username, password) => {
  let wasMappedSuccesfully = false;
  let haveCredentialsbeenSubmited = false;
  let responseServerMessage = "";

  if(username && password){

    const endpointResponse = await mapTrainingCredentials(username, password, UserService.getToken(), "redux call");

    wasMappedSuccesfully = (endpointResponse?.wasMappedSuccesfully) ? true : false;
    responseServerMessage = endpointResponse?.responseMessage;
    haveCredentialsbeenSubmited = true;
  }

  return{
    type: SUBMIT_TRAINING_CREDENTIALS,
    areCredentialsMappedSuccesfully: wasMappedSuccesfully,
    haveCredentialsbeenSubmited: haveCredentialsbeenSubmited,
    responseServerMessage: responseServerMessage
  }
}



