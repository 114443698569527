import React, { useEffect } from "react";
import { connect } from 'react-redux';
import SideNav from '../../components/layout-components/SideNav';
import TopNav from '../../components/layout-components/TopNav';
import Loading from '../../components/shared-components/Loading';
import MobileNav from '../../components/layout-components/MobileNav'
import HeaderNav from '../../components/layout-components/HeaderNav';
import PageHeader from '../../components/layout-components/PageHeader';
import QuicksightDashboardWrapper from '../../components/layout-components/landing-dashboards/provider-landing-dashboard-wrapper/QuicksightDashboardWrapper';
import AppViews from '../../views/app-views';
import {
  Layout,
  Grid,
} from "antd";

import mainNavigationConfig from "../../configs/MainNavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from '../../constants/ThemeConstant';
import utils from '../../utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import { onNewExperienceToogleVisibilityChange, onNewExperienceToogleValueChange } from '../../redux/actions/General';
import { onLoadingAnyXMpageRequest, onChangingCurrentLocation } from '../../redux/actions/Experience';
import { env } from "../../configs/EnvironmentConfig";


const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction, isMainGeneralToogleValueOn, onChangingCurrentLocation,
  selectedAgencyData, onNewExperienceToogleVisibilityChange, onNewExperienceToogleValueChange, onLoadingAnyXMpageRequest, configurationDisplaySettingsPerPage }) => {

  const currentRouteInfo = utils.getRouteInfo(mainNavigationConfig, location?.pathname);
  const currentPage = currentRouteInfo?.path?.substring(currentRouteInfo?.path?.lastIndexOf('/') + 1);

  useEffect(() => {
    if (currentPage) {
      onLoadingAnyXMpageRequest(currentPage, selectedAgencyData?.CompanyId, selectedAgencyData?.GroupId, selectedAgencyData?.CompanyGroupIds);
      onChangingCurrentLocation(currentPage);
    }
  }, [currentPage, selectedAgencyData, onChangingCurrentLocation, onLoadingAnyXMpageRequest]);


   // Side effect for new experience toggle visibility/value
   useEffect(() => {
    if (selectedAgencyData) {
      if (selectedAgencyData?.GroupId !== 2 && env.ENVIROMENT === 'prod') {
        onNewExperienceToogleVisibilityChange(false);
        onNewExperienceToogleValueChange(false);
      } else {
        const isDisplayToogle = (configurationDisplaySettingsPerPage?.isToDisplayDualView === true && configurationDisplaySettingsPerPage?.isToDisplaySingleView === false);
        onNewExperienceToogleValueChange(isMainGeneralToogleValueOn);
        onNewExperienceToogleVisibilityChange(isDisplayToogle);
      }
    }
  }, [selectedAgencyData, isMainGeneralToogleValueOn, configurationDisplaySettingsPerPage, onNewExperienceToogleValueChange, onNewExperienceToogleVisibilityChange]);



  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }


  let parentTitle = utils.getParentSubmenuTitleFromPath(currentRouteInfo?.path);
  let filteredNavigation = utils.filterSelectedNavigationOptions(parentTitle, currentRouteInfo?.title, mainNavigationConfig);
  let isToDisplayQuicksightExperience = utils.isPathRegisteredForQuicksightExperience(currentRouteInfo?.path, location?.pathname);
  let groomedPath = utils.getGroomedLocationFromPath(currentRouteInfo?.path, location?.pathname);

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} pathLocation={groomedPath} />

      {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo} /> : null}

      <Layout className="app-container">
        {/* TODO: If we are to remove the sidebar for the selection then we need to change this if statement */}
        {((isNavSide && !isMobile) && filteredNavigation?.isServiceAvailableForUser) ? <SideNav navColor={filteredNavigation?.color} navTitle={filteredNavigation ? filteredNavigation?.sideTitle : "Experience"} routeInfo={currentRouteInfo} /> : null}

        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div id="inner-app-content" className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={true} title={currentRouteInfo?.title} />

            <Content>
              <AppViews />
              {/* We dont want this container to be removed from the DOM even if its not to active          */}
              <QuicksightDashboardWrapper isToDisplayQuicksightExperience={isToDisplayQuicksightExperience} />
            </Content>
          </div>
        </Layout>
      </Layout>

      {isMobile && <MobileNav />}
    </Layout>
  )
}

const mapStateToProps = ({ theme, general, cip, experience }) => {
  const { onLoadingAnyXMpageRequest, configurationDisplaySettingsPerPage, onChangingCurrentLocation } = experience;
  const { navCollapsed, navType, locale } = theme;
  const { isMainGeneralToogleValueOn, onNewExperienceToogleVisibilityChange, onNewExperienceToogleValueChange } = general
  const { selectedAgencyData } = cip;
  return {
    navCollapsed, navType, locale, onLoadingAnyXMpageRequest,
    isMainGeneralToogleValueOn, onNewExperienceToogleVisibilityChange, onChangingCurrentLocation,
    onNewExperienceToogleValueChange, selectedAgencyData, configurationDisplaySettingsPerPage
  }
};

export default connect(mapStateToProps, { onNewExperienceToogleVisibilityChange, onNewExperienceToogleValueChange, onLoadingAnyXMpageRequest, onChangingCurrentLocation })(React.memo(AppLayout));