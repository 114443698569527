import * as companyConst from 'constants/CompanyConstant';

export const getCompiledDWCompanyUUIDByCompanyId = async(companyId) => {
  let companyUUIDs = []
  if(companyId === 1748){
    companyUUIDs.push(companyConst.RIGHT_AT_HOME_USA_DW_COMPANY_GROUP_ID)
  }

  return companyUUIDs;
}


const TemporalModificationsService = {
  getCompiledDWCompanyUUIDByCompanyId
};

export default TemporalModificationsService;
