import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import mainNavigationConfig from "../../configs/MainNavigationConfig";
import IconFallback from "../util-components/IconFallback";
import Icon from "../util-components/Icon";
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {toggleSelectedUpperNavigationTabOnClick, toggleSelectedUpperNavigationTabOnLoad} from '../../redux/actions/Cip';
import { useLocation } from 'react-router-dom';
import { env } from '../../configs/EnvironmentConfig'
import { onMobileNavToggle } from "../../redux/actions/Theme";
const { SubMenu } = Menu;

const setLocale = (isLocaleOn, localeKey) =>
	isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey?.toString();

const MenuContentTop = (props) => {
	const { topNavColor, localization, toggleSelectedUpperNavigationTabOnClick, toggleSelectedUpperNavigationTabOnLoad } = props;

	toggleSelectedUpperNavigationTabOnLoad(useLocation().pathname);

	if(env.IS_NATIVE_GLOBAL_NAV_FEATURE_ON){		
		return (
			<Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
				{mainNavigationConfig.map((menu, index) =>
					menu.globalSubmenu?.length > 0 ? 
					(					
						<SubMenu
							key={menu?.key}
							title={<span class="cip-global-submenu-title">{setLocale(localization, menu?.title)}</span>}
							icon={
								menu.icon ? (
									<span className="cip-global-submenu-icon-parent"><Icon type={menu?.icon} /></span>
								) :<span className="cip-global-submenu-icon-parent"><IconFallback path={menu?.iconAlt} /></span> 
							}
						>							
							{menu.globalSubmenu.map((subMenuFirst) =>
								subMenuFirst.submenu.length > 0 ? (							
									<SubMenu
										key={subMenuFirst.key}
										title={<span class="cip-global-submenu-title">{setLocale(localization, subMenuFirst?.title)}</span>}
										
									>
										{
											subMenuFirst.submenu.map((subMenuSecond) => (
												<Menu.Item key={subMenuSecond.key}>
													<span>
														{setLocale(localization, subMenuSecond.title)}
													</span>
													{/* <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} /> */}
													{subMenuSecond.path ? <NavLink to={subMenuSecond.path}/> : null}
												</Menu.Item>
											))
										}
									</SubMenu>
								) : (
									<Menu.Item key={subMenuFirst.key}>
										<span className="cip-global-submenu-icon">{subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : <IconFallback path={subMenuFirst.iconAlt} />}</span>
										<span>{setLocale(localization, subMenuFirst.title)}</span>
										{/* <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} /> */}
										{subMenuFirst.path ? <NavLink to={subMenuFirst.path}/> : null}
									</Menu.Item>
								)
							)
							}
						</SubMenu>
					):(
						<Menu.Item key={menu?.key} className={menu?.current ? 'current' : null } onClick={() => toggleSelectedUpperNavigationTabOnClick(menu)}>
							<IconFallback path={menu?.iconAlt} iconPosition={menu?.iconPosition}/>
							<span>{setLocale(localization, menu?.title)}</span>
							{menu.path ? <NavLink to={menu?.path}/> : null}										
						</Menu.Item>
					)
				)}
			</Menu>
		);
	}else{		
		return (
			<Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
				{mainNavigationConfig.map((menu, index) =>
					<Menu.Item key={menu?.key} className={menu.current ? 'current' : null } onClick={() => toggleSelectedUpperNavigationTabOnClick(menu)}>
						<IconFallback path={menu.iconAlt} iconPosition={menu.iconPosition}/>
						<span>{setLocale(localization, menu?.title)}</span>
						{menu.path ? <NavLink to={menu.path}/> : null}										
					</Menu.Item>
				)}
			</Menu>
		);
	}	
};

function mapDispatchToProps(dispatch){
	return bindActionCreators({
        toggleSelectedUpperNavigationTabOnClick: toggleSelectedUpperNavigationTabOnClick,
		toggleSelectedUpperNavigationTabOnLoad: toggleSelectedUpperNavigationTabOnLoad,
		onMobileNavToggle: onMobileNavToggle
	}, dispatch)
}

const mapStateToProps = ({cip}) => {
	const {selectedService, serviceOnLoad} = cip ;
	return {selectedService, serviceOnLoad} 
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuContentTop);
