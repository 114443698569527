export const ON_COMPANY_LOCATIONS_REQUEST = 'ON_COMPANY_LOCATIONS_REQUEST'; 
export const ON_SELECTING_COMPANY_FROM_LOCATION = 'ON_SELECTING_COMPANY_FROM_LOCATION';
export const ON_SELECTING_LOCATION_VIEW_ID = 'ON_SELECTING_LOCATION_VIEW_ID';
export const ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN = 'ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN';
export const ON_CONTACT_CORRECTIONS_REQUEST = 'ON_CONTACT_CORRECTIONS_REQUEST';
export const ON_LOADING_LOCATIONS_VIEW = 'ON_LOADING_LOCATIONS_VIEW';
export const ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST = 'ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST';
export const ON_LOADING_ANY_XM_PAGE_REQUEST = 'ON_LOADING_ANY_XM_PAGE_REQUEST';
export const ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD = 'ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD';
export const ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD = 'ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD';
export const ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD = 'ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD';
export const ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD = 'ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD';
export const ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD = 'ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD';
export const ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD = 'ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD';
export const ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP = 'ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP';
export const ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD = 'ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD';
export const ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD = 'ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD';
export const ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD = 'ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD';
export const ON_SEARCHING_FOR_COMPANIES = 'ON_SEARCHING_FOR_COMPANIES';
export const ON_REQUESTING_DATE_CUSTOM_CONFIGURATION = 'ON_REQUESTING_DATE_CUSTOM_CONFIGURATION';
export const ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER = 'ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER';
export const ON_TESTING_REDUX = 'ON_TESTING_REDUX';
export const ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC = 'ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC';
export const ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER= 'ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER';
export const ON_CHANGING_CURRENT_LOCATION = 'ON_CHANGING_CURRENT_LOCATION';
export const ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL = 'ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL';
export const ON_AWS_QS_URL_MOUNTED = 'ON_AWS_QS_URL_MOUNTED';
export const ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS = 'ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS';
export const ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST = 'ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST';
export const ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY = 'ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY';
export const ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD = 'ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD';
export const ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS = 'ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS';
export const ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC = 'ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC';
export const ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD = 'ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD';
export const ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC = 'ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC';
export const ON_SETTING_NPS_OSAT_TABLE = 'ON_SETTING_NPS_OSAT_TABLE';
export const ON_SELECTING_RECORD_TO_EDIT = 'ON_SELECTING_RECORD_TO_EDIT';
export const ON_SUBMITTING_GOAL_ACTION = 'ON_SUBMITTING_GOAL_ACTION';
export const ON_SELECTING_RECORD_KEY_TO_EDIT = 'ON_SELECTING_RECORD_KEY_TO_EDIT';