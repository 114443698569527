import { APP_PREFIX_PATH } from '../../AppConfig';
import { env } from "../../EnvironmentConfig";


export const getGlobalExperienceDashBoardSubmenu = (companyId, userName, groupId) => {

	const globalExperienceDashBoardNavTree = [
		{
			key: 'topnav.submenu.experience.activatedInsights',
			path: `${APP_PREFIX_PATH}/experience/activated-insights`,
			title: 'Activated Insights',
			icon: '',
			iconAlt:'/img/globalNav/icon-ai.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'topnav.submenu.experience.pinnacleRetain',
			path: `${APP_PREFIX_PATH}/experience/pinnacle-retain`,
			title: 'Pinnacle Retain',
			icon: '',
			iconAlt:'/img/globalNav/icon-pinnacle-retain.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'topnav.submenu.experience.pinnacleQI',
			path: `${APP_PREFIX_PATH}/experience/pinnacle-qi`,
			title: 'Pinnacle QI',
			icon: '',
			iconAlt:'/img/globalNav/icon-pinnacle-qi.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'topnav.submenu.experience.pinnacleCAHPS',
			path: `${APP_PREFIX_PATH}/experience/pinnacle-cahps`,
			title: 'Pinnacle CAHPS',
			icon: '',
			iconAlt:'/img/globalNav/icon-pinnacle-qi.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		}
	]
	
	return env.IS_NATIVE_GLOBAL_NAV_FEATURE_ON ? globalExperienceDashBoardNavTree : [];
	
}