export const MAIN_UPPER_TOOGLE_VALUE = 'MAIN_UPPER_TOOGLE_VALUE'; 
export const DISPLAY_MAIN_GENERAL_TOOGLE = 'DISPLAY_MAIN_GENERAL_TOOGLE';
export const ON_SHIPPING_TAB_CHANGE = 'ON_SHIPPING_TAB_CHANGE';
export const ON_EDIT_ADDRESS_CHANGE = 'ON_EDIT_ADDRESS_CHANGE';
export const ON_EDIT_USER_PROFILE_CHANGE = 'ON_EDIT_USER_PROFILE_CHANGE';
export const ON_ADDRESS_REQUEST = 'ON_ADDRESS_REQUEST';
export const ON_SELECTING_CORRECTION_MODAL = 'ON_SELECTING_CORRECTION_MODAL';
export const ON_SELECTING_CORRECTION_RECORD = 'ON_SELECTING_CORRECTION_RECORD';
export const ON_SELECTING_ROW_KEYS = 'ON_SELECTING_ROW_KEYS';
export const ON_CHANGING_TABS_IN_INTERVIEWS_DASHBOARD = 'ON_CHANGING_TABS_IN_INTERVIEWS_DASHBOARD';
export const ON_DOWNLOADING_ROW_KEYS_TRANSFORM = 'ON_DOWNLOADING_ROW_KEYS_TRANSFORM';
export const ON_SELECTING_REPORT_TAB = 'ON_SELECTING_REPORT_TAB';
export const ON_SELECTING_PRINTING_FOR_ALL_ROWS = 'ON_SELECTING_PRINTING_FOR_ALL_ROWS';
export const ON_FILTERING_TABLE_RESULTS = 'ON_FILTERING_TABLE_RESULTS';