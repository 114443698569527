import React from 'react'

export const Title = ({ title, color, isCollapsed}) => {
	let classToUse = "title ";
	classToUse = title.length > 10 ? classToUse.concat("lengthyTitle") : classToUse.concat("shortTitle");
	if(!isCollapsed){
		return (
			<h1 className={classToUse} style={{background:color}}>
				<strong>HCP</strong> {title}
			</h1>
		)
	}else{
		return (
			<h1 className={classToUse} style={{background:color}}>
				<strong>HCP</strong>
			</h1>
		)
	} 

}

export default Title