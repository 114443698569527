import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux';
import { APP_PREFIX_PATH } from '../../configs/AppConfig';
import { 
  EditOutlined, 
  ShopOutlined, 
  LogoutOutlined,
  CloudUploadOutlined
} from '@ant-design/icons';
import Icon from '../../components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import UserService from '../../services/UserService';
import { Link } from "react-router-dom";
import { env } from "../../configs/EnvironmentConfig";

const configureMenuItems = (selectedAgencyData) => {
  const menuLinks = [    
      // {
      // title: "Switch Accounts",
      // icon: SettingOutlined,
      // path: ""
      // }
  ];

  if(env.IS_BILLING_PROFILE_OPTION_ON ){
    menuLinks.push({
      title: "Billing",
      icon: ShopOutlined ,
      path: "profile/billing"
    });
  }

  if(env.IS_EDIT_PROFILE_OPTION_ON){
    menuLinks.push({
      title: "Edit Profile",
      icon: EditOutlined ,
      path: "profile/edit-profile"
      });
  }
  
  if(selectedAgencyData && selectedAgencyData?.IsCompanyGroup){
    menuLinks.push({
      title: "File Uploads",
      icon: CloudUploadOutlined,
      path: "file-upload-corp",
    });
  }else{
    menuLinks.push({
      title: "File Uploads",
      icon: CloudUploadOutlined,
      path: "file-upload-provider",
    });
  }

  menuLinks.push({
    title: "Log Out",
    icon: LogoutOutlined,
    path: "logout"
  });

  return menuLinks;
}

export const NavProfile = (props, {signOut}) => {
  const { selectedAgencyData } = props;
  const menuItems = configureMenuItems(selectedAgencyData);
  const profileImg = "/img/avatars/tempProfile.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{UserService.getGivenName() ?? UserService.getUsername()}</h4>                        
            <span className="text-muted">{selectedAgencyData?.CompanyName ?? "Demo Agency"}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItems?.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link id={el.id} to={`${APP_PREFIX_PATH}/${el.path}`}>
                  <Icon className="mr-3 profile-accomdation" type={el.icon} />
                    <span className="font-weight-normal profile-text-accomodation">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <div className="avatar-menu d-flex align-items-center" mode="horizontal">
        <Avatar size={40} src={profileImg} />
      </div>
    </Dropdown>
  );
}

const mapStateToProps = ({ cip }) => {
	const { selectedAgencyData } =  cip;
	return { selectedAgencyData }
};

export default connect(mapStateToProps, {signOut})(NavProfile)
