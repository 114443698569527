import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onRequestingQuicksightReportsForUser } from 'redux/actions/Experience';
import Loading from '../../../shared-components/Loading';
import $ from 'jquery';
import * as companyConst from 'constants/CompanyConstant';
import { env } from 'configs/EnvironmentConfig';


class QuicksightDashboardWrapper extends Component {

  componentDidMount() {
    this.hideOrShowAwsIframes();
  }

  componentDidUpdate() {
    this.hideOrShowAwsIframes();
  }

  hideOrShowAwsIframes = () => {
    const currentPath = this.getCurrentLocationBasedOnPath();
    const currentObject = this.props.quicksightFetchedDashboards?.find(dashboard => dashboard?.embeddingLocation === currentPath);
    const reportId = currentObject?.dashboardIds[0];
    $('#quicksight-dashboard-container').find('iframe').each(function() {
      const iframeId = $(this).attr('id');
      const substring = reportId;
    
      if (iframeId && !iframeId.includes(substring)) {
        $(this).hide();
      }else{
        $(this).show();
      }
    });
  }

  getCurrentLocationBasedOnPath = () => {
    const path = window.location.pathname.split('/');
    const currentPath = path[path.length - 1];
    return currentPath;
  }

  render() {
    
    if(env.IS_QUICKSIGHT_COMPONENT_ON && (this.props.selectedAgencyData?.CompanyGroupIds?.includes(companyConst.RIGHT_AT_HOME_USA_DW_COMPANY_GROUP_ID))){
      if (this.props.isToDisplayQuicksightExperience && !this.props.quicksight) {
        return (
          <div>
            <Loading cover="content" />
          </div>
        )
      }
          
      return (
        <div>
          <div>
            <div id="quicksight-dashboard-container" className={(this.props.isToDisplayQuicksightExperience) ? '' : 'hide-inner-graph'}/>          
          </div>
        </div>
      )
    }


    return(
      <div></div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onRequestingQuicksightReportsForUser: onRequestingQuicksightReportsForUser
  }, dispatch)
}

const mapStateToProps = ({ cip, experience }) => {
  const { selectedAgencyData } = cip;
  const { currentLocation, quicksight, quicksightFetchedDashboards } = experience;
  return { selectedAgencyData, currentLocation, quicksight, quicksightFetchedDashboards }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuicksightDashboardWrapper);